import * as actionTypes from '../actions/actionTypes'


import strings from '../../assets/strings/Errors'

const initialState = {
  profile: null,
  userId: null,
  error: null,
  loading: false,
  authRedirect: '/',
  language: 0
}


const reducer = (state = initialState, action) => {
  // eslint-disable-next-line 
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {
        ...state,
        error: null,
        loading: true
      }
    case actionTypes.AUTH_FAIL:
      let error
      if(action.error && strings[0].hasOwnProperty(action.error)){
        error = strings[state.language][action.error]
      }else{
        error = action.error
      }
      return {
        ...state,
        error: error,
        loading: false
      }
    case actionTypes.AUTH_SUCCESS:

        
        return {
          ...state,
          profile: action.profile,
          userId: action.uid,
          error: null,
          loading: false
        }

    case actionTypes.AUTH_LOGOUT:
      
      return {
        ...state,
        profile: null,
        userId: null,
        error: null,
        loading: false,
        authRedirect: '/'
      }
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return {
        ...state,
        authRedirect: action.path
      }
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.language
      }

  }
  return state;
}

export default reducer;
