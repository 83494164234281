import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loadable from "react-loadable";
import PageLoading from "./components/PageLoading/PageLoading";
import PageNotFound from "./screens/PageNotFound";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { authCheckState } from "./store/actions/auth";

import {
  initGA,
  PageView,
  ObservePerformance,
  SetTrackedUserData,
} from "./Tracking";



// import ReactGA from "react-ga";
// ReactGA.initialize("UA-136494823-1");
// ReactGA.pageview(window.location.pathname + window.location.search);

const AsyncLandingPage = Loadable({
  loader: () => import("./screens/LandingPage"),
  loading: PageLoading,
});
const AsyncLegalsPage = Loadable({
  loader: () => import("./screens/LegalsPage"),
  loading: PageLoading,
  delay: 1,
});
const AsyncConfirm = Loadable({
  loader: () => import("./screens/ConfirmPage"),
  loading: PageLoading,
});
const AsyncVerify = Loadable({
  loader: () => import("./screens/Verification"),
  loading: PageLoading,
});
const AsyncForgotPassword = Loadable({
  loader: () => import("./screens/ForgotPassword"),
  loading: PageLoading,
});
const AsyncResetPassword = Loadable({
  loader: () => import("./screens/ResetPassword"),
  loading: PageLoading,
});
const AsyncLogin = Loadable({
  loader: () => import("./screens/Login"),
  loading: PageLoading,
});
const AsyncHome = Loadable({
  loader: () => import("./screens/Home"),
  loading: PageLoading,
});
const AsyncQuickAssessment = Loadable({
  loader: () => import("./screens/QuickAssessment"),
  loading: PageLoading,
  delay: 1,
});
const AsyncVolume2D = Loadable({
  loader: () => import("./screens/Volume2D"),
  loading: PageLoading,
  delay: 1,
});

class App extends Component {
  componentDidMount = () => {
    initGA("UA-136494823-1");
    PageView();
    ObservePerformance();
    this.props.onAuthCheckState();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.userId !== prevProps.userId) {
      this.props.onAuthCheckState();
      if (
        this.props.userId &&
        this.props.firstName &&
        this.props.lastName &&
        this.props.email &&
        this.props.country
      )
        SetTrackedUserData(this.props.userId);
    }
  };

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/" exact component={AsyncLandingPage} />
          <Route path="/legals/:document" component={AsyncLegalsPage} />
          <Route path="/login" component={AsyncLogin} />
          <Route path="/verification" exact component={AsyncVerify} />
          <Route path="/reset" exact component={AsyncForgotPassword} />
          <Route path="/reset/:token" exact component={AsyncResetPassword} />
          <Route path="/confirmation/:token" exact component={AsyncConfirm} />
          <PrivateRoute
            path="/home"
            currentUser={this.props.userId}
            component={AsyncHome}
          />
          <PrivateRoute
            path="/apps/quick-assessment"
            currentUser={this.props.userId}
            component={AsyncQuickAssessment}
          />
          <Redirect exact from="/apps/bive" to="/apps/volume-estimator" />
          {/* <PrivateRoute
            path="/apps/bive"
            currentUser={this.props.userId}
            component={AsyncVolume2D}
          /> */}
          <PrivateRoute
            path="/apps/volume-estimator"
            currentUser={this.props.userId}
            component={AsyncVolume2D}
          />
          <PrivateRoute
            path="/apps/male-volume-estimator"
            currentUser={this.props.userId}
            component={AsyncVolume2D}
          />
          <Route path="/app" component={AsyncQuickAssessment} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    firstName:
      state.auth.userId && state.auth.profile && state.auth.profile.firstName,
    lastName:
      state.auth.userId && state.auth.profile && state.auth.profile.lastName,
    email: state.auth.userId && state.auth.profile && state.auth.profile.email,
    country:
      state.auth.userId && state.auth.profile && state.auth.profile.country,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthCheckState: () => dispatch(authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(App);
