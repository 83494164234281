//AUTH
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

//LOCALES
export const SET_LANGUAGE = 'SET_LANGUAGE';

//QUICK ASSESSMENT
export const QA_SET_MEASUREMENT_LANGUAGE = 'QA_SET_MEASUREMENT_LANGUAGE';
export const QA_RESET_APP = 'QA_RESET_APP';
export const QA_LOAD_PHOTO = 'QA_LOAD_PHOTO';
export const QA_REMOVE_SUPP_PHOTO = 'QA_REMOVE_SUPP_PHOTO';
export const QA_ROTATE_PHOTO = 'QA_ROTATE_PHOTO';

export const QA_START_SUBSECTION = 'QA_START_SUBSECTION';
export const QA_CHANGE_CURRENT_SUBSECTION = 'QA_CHANGE_CURRENT_SUBSECTION';
export const QA_MARK_SUBSECTION_COMPLETION = 'QA_MARK_SUBSECTION_COMPLETION';
export const QA_NEXT_POINT = 'QA_NEXT_POINT';
export const QA_TOGGLE_AUTOMATIC_PROGRESSION = "QA_TOGGLE_AUTOMATIC_PROGRESSION";
export const QA_TOGGLE_ANIMATED_INSTRUCTIONS = 'QA_TOGGLE_ANIMATED_INSTRUCTIONS';
export const QA_SWITCH_IMAGE = 'QA_SWITCH_IMAGE';
export const QA_CHANGE_CAN_DRAW = 'QA_CHANGE_CAN_DRAW';

export const QA_RETOUCH_PHOTO = 'QA_RETOUCH_PHOTO';
export const QA_COPY_RETOUCH = 'QA_COPY_RETOUCH';

export const QA_CHANGE_REFERENCE_VALUE = 'QA_CHANGE_REFERENCE_VALUE';

export const QA_CALCULATE_VALUES = 'QA_CALCULATE_VALUES';

export const QA_TOGGLE_MEASUREMENT = 'QA_TOGGLE_MEASUREMENT'; 
export const QA_TOGGLE_ALL_MEASUREMENTS = 'QA_TOGGLE_ALL_MEASUREMENTS';

export const QA_TOGGLE_POINT_NAMES = 'QA_TOGGLE_POINT_NAMES' ;
export const QA_TOGGLE_REFERENCE_LINES = 'QA_TOGGLE_REFERENCE_LINES';
export const QA_TOGGLE_ALL_OBJECTS = 'QA_TOGGLE_ALL_OBJECTS';

export const QA_ADD_CUSTOM_POINT = 'QA_ADD_CUSTOM_POINT';
export const QA_CHANGE_POINT_NAME = 'QA_CHANGE_POINT_NAME';
export const QA_CHANGE_POINT_COLOR = 'QA_CHANGE_POINT_COLOR';
export const QA_CHANGE_POINT_IMAGE = 'QA_CHANGE_POINT_IMAGE';
export const QA_UPDATE_CUSTOM_POINTS = 'QA_UPDATE_CUSTOM_POINTS';
export const QA_DELETE_CUSTOM_POINT = 'QA_DELETE_CUSTOM_POINT';

export const QA_ADD_CUSTOM_LINE = 'QA_ADD_CUSTOM_LINE';
export const QA_CHANGE_LINE_POINT = 'QA_CHANGE_LINE_POINT';
export const QA_DELETE_CUSTOM_LINE = 'QA_DELETE_CUSTOM_LINE';

export const QA_ADD_CUSTOM_MEASUREMENT = 'QA_ADD_CUSTOM_MEASUREMENT';
export const QA_DELETE_CUSTOM_MEASUREMENT = 'QA_DELETE_CUSTOM_MEASUREMENT';
export const QA_CHANGE_CUSTOM_MEASUREMENT_POINT = 'QA_CHANGE_CUSTOM_MEASUREMENT_POINT';
export const QA_CHANGE_CUSTOM_MEASUREMENT_TYPE = 'QA_CHANGE_CUSTOM_MEASUREMENT_TYPE';

export const QA_CHANGE_REPORT_INFO_VALUE = 'QA_CHANGE_REPORT_INFO_VALUE';

export const QA_TOGGLE_ANIMATION = 'QA_TOGGLE_ANIMATION';

export const QA_DELETE_SAVED_POINT = 'QA_DELETE_SAVED_POINT';
export const QA_LOAD_SAVED_POINTS = 'QA_LOAD_SAVED_POINTS';
export const QA_SAVE_CUSTOM_POINTS = 'QA_SAVE_CUSTOM_POINTS';
export const QA_DELETE_SAVED_LINE = 'QA_DELETE_SAVED_LINE';
export const QA_LOAD_SAVED_LINES = 'QA_LOAD_SAVED_LINES';
export const QA_SAVE_CUSTOM_LINES = 'QA_SAVE_CUSTOM_LINES';

//VOLUME2D
export const V2D_SET_MEASUREMENT_LANGUAGE = 'V2D_SET_MEASUREMENT_LANGUAGE';
export const V2D_RESET_APP = 'V2D_RESET_APP';
export const V2D_LOAD_PHOTO = 'V2D_LOAD_PHOTO';
export const V2D_REMOVE_SUPP_PHOTO = 'V2D_REMOVE_SUPP_PHOTO';
export const V2D_ROTATE_PHOTO = 'V2D_ROTATE_PHOTO';

export const V2D_START_SUBSECTION = 'V2D_START_SUBSECTION';
export const V2D_CHANGE_CURRENT_SUBSECTION = 'V2D_CHANGE_CURRENT_SUBSECTION';
export const V2D_MARK_SUBSECTION_COMPLETION = 'V2D_MARK_SUBSECTION_COMPLETION';
export const V2D_NEXT_POINT = 'V2D_NEXT_POINT';
export const V2D_TOGGLE_AUTOMATIC_PROGRESSION = "V2D_TOGGLE_AUTOMATIC_PROGRESSION";
export const V2D_TOGGLE_COPY_REF_LINE_INPUT = "V2D_TOGGLE_COPY_REF_LINE_INPUT"
export const V2D_TOGGLE_ANIMATED_INSTRUCTIONS = 'V2D_TOGGLE_ANIMATED_INSTRUCTIONS';
export const V2D_SWITCH_IMAGE = 'V2D_SWITCH_IMAGE';
export const V2D_RESET_IMAGE_POSITON_AND_SCALE = "V2D_RESET_IMAGE_POSITON_AND_SCALE";
export const V2D_CHANGE_CAN_DRAW = 'V2D_CHANGE_CAN_DRAW';

export const V2D_RETOUCH_PHOTO = 'V2D_RETOUCH_PHOTO';
export const V2D_COPY_RETOUCH = 'V2D_COPY_RETOUCH';

export const V2D_CHANGE_REFERENCE_VALUE = 'V2D_CHANGE_REFERENCE_VALUE';

export const V2D_SEND_VALUES = 'V2D_SEND_VALUES';
export const V2D_RECEIVE_VALUES = 'V2D_RECEIVE_VALUES';

export const V2D_TOGGLE_MEASUREMENT = 'V2D_TOGGLE_MEASUREMENT'; 
export const V2D_TOGGLE_ALL_MEASUREMENTS = 'V2D_TOGGLE_ALL_MEASUREMENTS';

export const V2D_TOGGLE_POINT_NAMES = 'V2D_TOGGLE_POINT_NAMES' ;
export const V2D_TOGGLE_REFERENCE_LINES = 'V2D_TOGGLE_REFERENCE_LINES';
export const V2D_TOGGLE_ALL_OBJECTS = 'V2D_TOGGLE_ALL_OBJECTS';

export const V2D_CHANGE_REPORT_INFO_VALUE = 'V2D_CHANGE_REPORT_INFO_VALUE';

export const V2D_TOGGLE_ANIMATION = 'V2D_TOGGLE_ANIMATION';
export const V2D_TOGGLE_VISUALIZATION = 'V2D_TOGGLE_VISUALIZATION';
export const V2D_TOGGLE_ALGORITHM = 'V2D_TOGGLE_ALGORITHM';

export const V2D_LEAVE_PAGE = 'V2D_LEAVE_PAGE';
export const V2D_SAVE_DATA = 'V2D_SAVE_DATA';
export const V2D_SAVE_DATA_CONFIRMATION = 'V2D_SAVE_DATA_CONFIRMATION';

export const V2D_START_TUTORIAL = "V2D_START_TUTORIAL";
export const V2D_SAVE_CANVAS_SIZE = "V2D_SAVE_CANVAS_SIZE"