import React, { Component } from 'react'
import styled from 'styled-components';
import logo from '../assets/images/logo.svg'
import { PageView } from '../Tracking';

const Container = styled.div`
height: 100vh;
width: 100vw;
display: flex;
justify-content: space-around;
flex-direction: column;
align-items: center;
background-color: #374050;
font-family: 'OpenSans', sans-serif;
`
const Image = styled.img`
flex-basis: 30%;
width: 30%;
`

const Text = styled.div`
color: #fff;
font-size: 2em;
text-align:center;
`

const Button = styled.div`
box-shadow: 0px 0px 4px 1px rgba(218, 165, 32, 1);
background-color: rgba(242, 242, 242, 1);
color: #3A3A3A;
font-family: inherit;
font-size: 1.2em;
font-weight: bolder;
box-sizing: border-box;
padding: 12px;
margin: 2em;

cursor: pointer;
transition: all 0.25s linear;
&:hover{
    box-shadow: 0px 0px 4px 3px rgba(218, 165, 32, 1);
}
&:disabled{
    background-color: rgba(123, 123, 123, 1);
    box-shadow: none;
    cursor: not-allowed;
}
`

class PageNotFound extends Component {
    state = {
        language: 1
    }

    componentDidMount = () => {
        PageView()
        let lang = localStorage.getItem("language")
        if (lang) {
            this.setState({ language: parseInt(lang) })
        } else {
            this.setState({ language: 1 })
        }
    }

    handleGoHome = () => {
        this.props.history.push('/home')
    }

    render() {
        let message, goHome
        if (this.state.language === 0) {
            message = 'Błąd 404 - Nie odnaleziono strony.'
            goHome = 'Powrót do strony głównej'
        } else {
            message = '404 Error - Page not found.'
            goHome = 'Back to Home Page'
        }
        return (
            <Container>
                <Image src={logo} alt='logo' />
                <Text>{message}</Text>
                <Button onClick={this.handleGoHome}>{goHome}</Button>
            </Container>
        )
    }
}

export default PageNotFound
