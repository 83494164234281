export const strings = [
    {
        mainApp: {
            loadImageInfo: 'Wczytaj zdjęcia w panelu po lewej stronie'
        },
        sideDrawer: {
            maleVolumeDescription: `Męski moduł BIVE (BIVE-M) przeznaczony jest do szacowania objętości męskich piersi na podstawie zdjęć. Moduł może być wykorzystywany do celów naukowych i klinicznych (np. do monitorowania terapii farmakologicznych) u mężczyzn z powiększonym biustem. Uwzględnia różnicę między klatką piersiową i piersiami kobiet i mężczyzn: wyniosłość mięśnia piersiowego, ograniczona powierzchnia gruczołu u mężczyzn (zwykle w centralnej części piersi, a nie między boczną linią mostka a przednią linią pachową jak u kobiet). Jednak w przypadkach, gdy męska klatka piersiowa/piersi ma cechy typowe dla kobiecych piersi (obszar piersi i ptoza – obecność fałdu podpiersiowego) – należy zastosować moduł dla kobiecej piersi (BIVE ).

            Walidacja modułu oparta na osobach transseksualnych na różnych etapach hormonoterapii opierała się na zastosowaniu modułów najlepiej dopasowanych. (Dostępne w artykule: w trakcie recenzji)`,
            backText: 'Powrót do menu',
            appTitle: 'Ocena Objętości',
            appTitleMale: 'Ocena Objętości - M',
            useChromeSuggestion: 'Dla maksymalnej jakości użytkowania, polecamy otwierać aplikację w przeglądarce Google Chrome.',
            validationHere: 'Artykuł prezentujący walidację obecnie w recenzji',
            automaticProgression: 'Automatycznie uruchamiaj kolejny etap',
            copyReferenceLineInput: 'Wszystkie odcinki referencyjne są tej samej długości',
            showAnimatedInstructions: 'Pokazuj animowane instrukcje w trakcie dodawania punktów',
            sections: {
                photoLoad: 'Wczytanie fotografii',
                photoEdit: 'Obróbka zdjęć',
                referenceLines: 'Odcinki referencyjne',
                sagittalXsection: 'Przekrój strzałkowy',
                transverseXsection: 'Przekrój poprzeczny',
                report: 'Raport'

            },
            loadRightPhoto: "Wczytaj fotografię prawej strony pacjenta:",
            loadLeftPhoto: "Wczytaj fotografię lewej strony pacjenta:",
            loadFrontPhoto: "Wczytaj fotografię przodu pacjenta:",
            skipPhotoEdit: "Pomiń obróbkę zdjęć",
            rightImageEdit: 'Edit zdjęcia z prawej strony',
            leftImageEdit: 'Edit zdjęcia z lewej strony',
            frontImageEdit: 'Edit zdjęcia z przodu',
            brightness: 'Jasność',
            contrast: 'Kontrast',
            saturation: 'Nasycenie',
            copyMainImageValues: 'Powiel wartości aktualnie edytowanego zdjęcia',
            fixRotation: 'Popraw przekrzywione zdjęcie',
            fixSuppRotation: 'Dopasuj rotację dodatkowego zdjęcia',
            fixInstructionMain: 'Oznacz dwa charakterystyczne punkty na pierwszym zdjęciu',
            fixInstructionSupp: 'W tej samej kolejności oznacz te same punkty na drugim zdjęciu',
            edit: 'Edytuj',
            next: 'Dalej',
            point: 'Punkt',
            line: 'Odcinek',
            adjustRotation: 'Dopasuj',
            refLineHeadingRight: 'Oznacz odcinek referencyjny na zdjęciu z prawej strony',
            refLineHeadingLeft: 'Oznacz odcinek referencyjny na zdjęciu z lewej strony',
            refLineHeadingFront: 'Oznacz odcinek referencyjny na zdjęciu z przodu',
            refLineInstruction: "Oznacz dwa końce odcinka na ciele pacjenta. Następnie wpisz ile wynosiła długość odcinka w centymetrach.",
            sagittalXsectionHeading: "Oznacz kontury piersi w płaszczyźnie strzałkowej",
            sagittalXsectionInstructionRight: "Oznacz kontur prawej piersi, ustawiając punkty na kolejno: górnym biegunie, fałdzie podpiersiowym, brodawce sutkowej. Następnie przeciągnij pomarańczowe punkty kontrolne, by krzywa opisywała obrys piersi.",
            sagittalXsectionInstructionLeft: "Oznacz kontur lewej piersi, ustawiając punkty na kolejno: górnym biegunie, fałdzie podpiersiowym, brodawce sutkowej. Następnie przeciągnij pomarańczowe punkty kontrolne, by krzywa opisywała obrys piersi.",
            transverseXsectionHeading: "Oznacz kontury piersi w płaszczyźnie czołowej",
            transverseXsectionMaleInstruction: {
                heading: "Wyznaczając boczne i przyśrodkowe brzegi piersi oraz punkt maksymalnej wyniosłości piersi, należy przestrzegać poniższych zasad:",
                point1: "1. Zaznacz granice piersi (tkanki tłuszczowej i/lub gruczołu), skupiając się tylko na wyniosłości leżącej nad tłem (mięsień piersiowy i ogólna warstwa tkanki tłuszczowej pokrywającej klatkę piersiową).",
                point2: "2. Jeżeli nie masz pewności co do identyfikacji przyśrodkowych i bocznych granic wzniesienia na fotografii, możesz zaznaczyć je na ciele pacjenta w trakcie badania bezpośredniego przed wykonaniem zdjęć.",
                point3: "3. Na bocznych granicach postaraj się również zarysować wzniesienie, które wyróżnia się nad tłem."
            },
            transverseXsectionRightInstruction: "Oznacz kontur prawej piersi, ustawiając punkty na najszerszym przekroju piersi, zaczynając od bocznej strony. Następnie przeciągnij pomarańczowe punkty kontrolne, by krzywa opisywała wybrany przekrój.",
            transverseXsectionLeftInstruction: "Oznacz kontur lewej piersi, ustawiając punkty na najszerszym przekroju piersi, zaczynając od bocznej strony. Następnie przeciągnij pomarańczowe punkty kontrolne, by krzywa opisywała wybrany przekrój.",
            rightSide: 'Strona prawa',
            leftSide: 'Strona lewa',
            topBorderInstructions: 'Wyznacz górną granicę każdej piersi, oznaczając wskazane punkty według instrukcji.',
            infraMammaryInstructions: 'Wyznacz fałd podpiersiowy każdej piersi, oznaczając wskazane punkty według instrukcji.',
            addCustomPoints: 'Własne punkty:',
            addCustomLines: 'Własne odcinki:',
            measurement: 'Pomiar',
            value: 'Wartość',
            breast: 'Pierś',
            volume: 'Objętość',
            rightBreast: 'Prawa',
            leftBreast: 'Lewa',
            selectAll: 'Zaznacz wszystkie',
            customMeasurements: 'Własne pomiary',
            customMeasurementDisclaimer: `Uwaga! Walidacja aplikacji była przeprowadzona na pomiarach widocznych w tabeli powyżej.
Własne pomiary będą poprawne matematycznie, lecz mogą nie odpowiadać pomiarom bezpośrednim. Prosimy traktować je z odpowiednią rezerwą.`,
            hidePointNames: 'Ukryj nazwy punktów',
            hideReferenceLines: 'Ukryj odcinki referencyjne',
            hideAllObjects: 'Ukryj wszystko',
            openReport: "Otwórz raport",
            addingPoint: 'Dodawanie punktów:',
            movingPoint: 'Przesuwanie punktów:',
            sagittal: 'Strzałkowy',
            transverse: 'Poprzeczny',
            hide: 'Ukryj',
            show: 'Pokaż',
            chooseAlgorithm: 'Wybierz aktywny algorytm:',
            sagittalAlgorithm: 'priorytet ma przekrój strzałkowy',
            transverseAlgorithm: 'priorytet ma przekrój poprzeczny',
            showModels: 'Wyświetl modele:',
            vizInfo: `W oknie zostaną wyświetlone trójwymiarowe modele piersi.
            Na podstawie podobieństwa modelu do rzeczywistego kształtu można dostosować ułożenie krzywych na zdjęciach.`,
            reportTableInfo: {
                patientData: 'Dane pacjenta',
                name: "Imię",
                surname: "Nazwisko",
                dateOfBirth: 'Data urodzenia',
                sex: "Płeć",
                female: 'Kobieta',
                male: 'Mężczyzna',
                assessorData: 'Dane badającego',
                assessorId: 'Identyfikator / Imię i nazwisko',
                comment: 'Komentarz',
                choose: 'Wybierz'
            },
            customPointBox: {
                image1: 'Zdjęcie główne',
                image2: 'Zdjęcie dodatkowe'
            },
            customLineBox: {
                point1: 'Punkt 1:',
                point2: 'Punkt 2:',
                choose: 'Wybierz'
            },
            customMeasurement: {
                distance: 'Odległość',
                levelDifference: 'Różnica wysokości',
                distanceToMedial: 'Odległość od linii pośrodkowej',
                choose: 'Wybierz'
            },
            loadAll: 'Wczytaj wszystkie',
            registration: {
                customPointsAndLines: 'Własne punkty i odcinki dostępne tylko dla zarejestrowanych użytkowników',
                haveAccount: 'Masz już konto?',
                signUp: 'Zarejestruj się',
                signIn: 'Zaloguj się',
                customMeasurements: 'Własne pomiary dostępne tylko dla zarejestrowanych użytkowników'
            },
            sendData: "Zapisz pomiar",
            startTutorialMode: "Uruchom Samouczek",
            tutorialPointFeedback: "Wskazówki umiejscowania punktów:",
            tutorialPointInstruction: "Poruszaj punktami zgodnie z kierunkami strzałek aż pokaże się ✔",
            tutorialRightBreast: "Prawa pierś:",
            tutorialLeftBreast: "Lewa pierś:",
            tutorialPoint: "Punkt",
            tutorialPointDescription: "Opis",
            upOrDown: "Góra / Dół",
            frontOrBack: "Przód / Tył",
            backOrFront: "Tył / Przód",
            lateralOrMedial: "Bocznie / Przyśrodkowo",
            medialOrLateral: "Przyśrodkowo / Bocznie",
            yourResult: "Twój wynik",
            idealResult: "Wartość prawidłowa"
        },
        firstRunModal: {
            welcome: 'Witaj w aplikacji BreastIdea - Ocena Objętości.',
            firstTime: 'Zauważyliśmy, że to Twój pierwszy raz tutaj, więc zachęcamy do zapoznania się z instrukcją obsługi.',
            showInstruction: 'Zobacz instrukcję',
            clicking: 'Klikając ',
            goBack: 'możesz zawsze do niej wrócić.',
            tutorial: "Aplikacja zostanie uruchomiona w trybie samouczka, by nauczyć Cię jak odpowiednio rozmieszczać punkty. Podpowiedzi będą wyświetlane w odpowiednich panelach po lewej stronie.",
            startApp: 'Rozpocznij samouczek',
            termsCb: [
                "Zapoznałam/em się z ",
                "Regulaminem",
                " aplikacji BreastIdea - Ocena Objętości i akceptuję jego treść.",
            ],
            termsNeeded: "Zaakceptowanie regulaminu jest konieczne do rozpoczęcia korzystania z aplikacji.",
            hideTerms: 'Ukryj Regulamin',
            terms: [
                "BreastIdea - Ocena Objętości jest aplikacją internetową dostarczaną przez serwis BreastIdea.",
                "Korzystając z niej użytkownik zobowiązany jest przestrzegać Warunków Ogólnych Serwisu oraz wymienionych w tym dokumencie. Brak zaakceptowania niniejszych warunków wiąże się z brakiem możliwości korzystania z serwisu.",
                "Aplikacja jest narzędziem pomocniczym. Użytkownik korzysta z niej na własne ryzyko, zapoznawszy się z procesem walidacji, z którego może uzyskać informacje dotyczące celności i precyzji uzyskiwanych wyników oraz ich korelacji z rzeczywistymi pomiarami.",
                "Twórcy nie odpowiadają za wyniki uzyskane w aplikacji i zrzekają się odpowiedzialności za wyniki uzyskane poprzez nieprawidłowe użytkowanie aplikacji lub błędy powstałe w wyniku problemów technicznych po stronie użytkownika lub stron trzecich, to jest dostawców usług hostingowych.",
            ]

        },
        tutorialStrings: {
            bannerPrimary: "Jesteś w Trybie Samouczka. Informacje zwrotne o umiejscowieniu punktów będą wyświetlane w odpowiednich sekcjach.",
            bannerSecondary: "Możesz zawsze wrócić do tego trybu klikając Uruchom Samouczek w lewym panelu.",
            endTutorial: "Zakończ samouczek",

        },

        instructions: {
            title: 'Ocena Objętości',

            interface: {
                header: 'Interfejs',
                text: {
                    leftPanel: 'Panel po lewej stronie ekranu zawiera sześć sekcji.',
                    eachSection: 'Każda sekcja składa się z instrukcji oraz przycisków umożliwiających wykonanie pomiarów.',
                    rightPanel: 'Panel po prawej stronie zawiera zdjęcia wczytane do aplikacji oraz menu.'
                }
            },
            usage: {
                header: 'Obsługa',
                text: {
                    inPointMode: 'W trybie punktu',
                    addPoint: `kliknięcie lewym przyciskiem myszy to dodawanie wybranego markera na zdjęcie. Kliknięcie i przytrzymanie na już istniejącym punkcie pozwala zmieniać jego położenie. `,
                    inMoveMode: 'W trybie kadrowania',
                    move: 'przytrzymanie lewego przycisku myszy i poruszanie kursorem umożliwia przemieszczanie zdjęcia.',
                    middleMove: 'W dowolnym trybie przytrzymanie kółka myszy (środkowego przycisku) i poruszanie kursorem również pozwala przemieszczać zdjęcia.',
                    inScaleMode: 'W trybie skalowania',
                    scale: 'przytrzymanie lewego przycisku i poruszanie kursorem pozwala zbliżać i oddalać obraz.',
                    middleScale: 'W dowolnym trybie poruszanie kółkiem myszy również pozwala zbliżać lub oddalać obraz.',
                    rmb: 'Kliknięcie prawym przyciskiem myszy na zdjęcie otwiera menu kontekstowe.'
                }
            },
            howToUse: {
                header: 'Jak używać aplikacji',
                text: {
                    load: 'Wczytaj trzy kolejne zdjęcia osoby badanej (z przodu, z prawej strony, z lewej strony)',
                    edit: 'Opcjonalnie popraw kolory zdjęcia/zdjęć',
                    refLine: 'Oznacz odcinek referencyjny na każdym ze zdjęć, a następnie podaj jego długość rzeczywistą (w centymetrach)',
                    markers: 'Postępuj według instrukcji by oznaczyć wszystkie wskazane markery',
                    table: 'W ostatniej sekcji wyświetlana jest aktualizowana w czasie rzeczywistym tabela, zawierająca pomiary objętości każdej z piersi',
                    form: 'Poniżej tabeli znajduje się formularz, w który możesz wpisać dane oraz komentarz do zdjęć',
                    algorithm: "Wybierz algorytm używany do obliczeń (omówiony dalej w tej instrukcji)",
                    fixPhoto: 'Ustaw (pozycja, przybliżenie) zdjęcia tak, jak chcesz by widniały w raporcie',
                    clickBtn: 'Kliknij przycisk',
                    report: 'Raport',
                    toOpen: 'by otworzyć gotowy do druku raport.',
                    reportFix: 'Przed wydrukiem dostosuj zawarte w raporcie zdjęcia',
                    press: 'Naciśnij przycisk',
                    print: 'Drukuj',
                    toPrint: 'by wydrukować rapot w formie fizycznej lub pliku PDF (sposób wyboru zależny od używanej przeglądarki)',
                    click: 'Kliknij',
                    return: 'Wróć',
                    toReturn1: 'by powrócić do aplikacji. Następnie w sekcji ',
                    toReturn2: 'Wczytaj zdjęcia',
                    toReturn3: ' naciśnij ',
                    toReturn4: 'Reset',
                    toReturn5: ' by rozpocząć nowe pomiary.',
                }
            },
            interfaceDiscussion: {
                header: 'Omówienie interfejsu',
            },


            sideDrawer: {
                header: 'Panel lewostronny',
                subtitles: {
                    showAnim: 'Pokazuj animowane instrukcje w trakcie dodawania punktów',
                    load: 'Wczytanie fotografii',
                    edit: 'Obróbka zdjęć',
                    ref: 'Odcinki referencyjne',
                    sagittalXsection: 'Przekrój strzałkowy',
                    transverseXsection: 'Przekrój poprzeczny',
                    report: 'Raport',
                    algorithm: 'Algorytm',
                    viz: 'Wizualizacja'
                },
                text: {
                    autoRun: 'po oznaczeniu wymaganych markerów, aplikacja automatycznie uruchamia kolejny etap pomiaru',
                    showAnim: 'gdy dodajesz punkty, wyświetlane są podstawowe, wizualne instrukcje obsługi markerów',
                    load: 'kliknięcie otwiera przeglądarkę plików. Wybierz zdjęcie osoby badanej. Dopuszczalne formaty to: .jpg, .png, .bmp, .gif.',
                    edit: `by lepiej rozpoznawać punkty orientacyjne na ciele osoby badanej, możesz dostosować jasność, kontrast i nasycenie zdjęcia.`,
                    ref: `na ciele pacjenta musi znajdować się znanej długości odcinek. 
                    Oznaczenie go i wpisanie jego rzeczywistej długości umożliwia przeliczenie pikseli zdjęcia na jednostki SI. Opcja automatycznego uruchamiania kolejnego etapu przyśpiesza tę część - po jej zaznaczeniu zdjęcia będą przełączać się po dodaniu wymaganych markerów.`,
                    sagittalXsection: `oznacz kontur prawej piersi, ustawiając punkty na kolejno: górnym biegunie, fałdzie podpiersiowym, brodawce sutkowej. Następnie przeciągnij pomarańczowe punkty kontrolne, by krzywa opisywała obrys piersi.`,
                    transverseXsection: `oznacz kontur prawej piersi, ustawiając punkty na najszerszym przekroju piersi, zaczynając od bocznej strony. Następnie przeciągnij pomarańczowe punkty kontrolne, by krzywa opisywała wybrany przekrój.`,
                    report: 'zobacz wyniki obliczeń, przeprowadzonych na podstawie krzywych. Możesz zmienić używany algorytm, wyświetlić trójwymiarowe modele piersi oraz wydrukować pełny raport do dokumentacji.',
                    algorithm: "zazwyczaj Algorytm Strzałkowy jest preferowany, ponieważ kontury ze zdjęć bocznych są łatwiejsze do wyznaczenia. Jednak jeśli wyznaczenie dobrego konturu bocznego jest niemożliwe, zaleca się stosowanie Algorytmu Poprzecznego, który wymaga poprawnego wyznaczenia przekroju poprzecznego każdej z piersi. Zalecane dla zaawansowanych użytkowników.",
                    viz: "by ułatwić naukę wyznaczania krzywych (szczególnie w przekroju poprzecznym), można skonfrontować skuteczność na podstwie modelu trójwymiarowego. Jeśli kształt modeli nie odpowiada prawdziwym piersiom, warto zmodyfikować krzywe, by przybliżony model był trafniejszy.",
                    vizMovement: [
                        "By poruszać się w oknie Wizualizacji:",
                        "Naciśnij i przytrzymaj lewy przycisk myszy by obracać kamerę.",
                        "Naciśnij i przytrzymaj prawy przycisk myszy by przesuwać kamerę.",
                        "Użyj kółka myszy by przybliżać lub oddalać kamerę."
                    ]

                }
            },
            photoArea: {
                header: 'Panel prawostronny',
                text: {
                    point: 'tryb punktu. W tym trybie możesz dodawać punkty i je przemieszczać.',
                    move: 'tryb kadrowania. W tym trybie możesz przemieszczać zdjęcie, by precyzyjniej dodawać markery.',
                    scale: 'tryb skalowania. W tym trybie możesz powiększać i pomniejszać obraz',
                    switch: 'zmień aktualnie wyświetlane zdjęcie. Niedostępne w trakcie dodawania punktów.',
                    manual: 'wyświetl instrukcję obsługi aplikacji.'
                }
            },
            contextMenu: {
                header: 'Menu kontekstowe',
                subtitles: {
                    reset: 'Resetuj obraz',
                    coords: 'Pokaż koordynaty punktu',
                    fps: 'FPS '
                },
                text: {
                    reset: 'wyzeruj przemieszczenie i przybliżenie obrazu.',
                    coords: 'nazwa i pozycja aktualnie wybranego punktu (pomarańczowa obwódka) zostanie pokazana w panelu w prawym górnym rogu zdjęcia.',
                    fps: `dostosuj jak często odświeżany jest obraz. 
Małe wartości to mniej płynne poruszanie punktów i zdjęcia, ale mniejsze obciążenie komputera. Minimum 5, maksimum 60 klatek na sekundę.`
                }
            },

        },
        photoArea: {
            nacInstructions: {
                nipple: 'Sutek: ',
                point: 'Punkt: ',
                right: 'Prawy',
                left: 'Lewy'
            },
            contextMenu: {

                reset: 'Resetuj pozycję obrazu',
                show: 'Pokaż',
                hide: 'Ukryj',
                pointCoordinates: ' koordynaty punktu',
                fps: 'FPS: '
            },
        },


        footer: {
            localComputation: `Oprogramowanie nie zapisuje i nie przechowuje danych wrażliwych. 
            Złożone obliczenia przeprowadzane są na naszym serwerze, natomiast zdjęcia pozostają lokalnie na urządzeniu użytkownika.`,
            ToS: `Regulamin Serwisu`,
            appToS: `Regulamin modułu Ocena Objętości`,
            PP: `Polityka Prywatności`,
            contact: `Kontakt`

        },
        printPage: {
            title: 'Szybki pomiar',
            patientData: 'Dane pacjenta',
            name: 'Imię',
            surname: 'Nazwisko',
            sex: 'Płeć',
            female: 'Kobieta',
            male: 'Mężczyzna',
            dateOfBirth: 'Data urodzenia',
            printDate: 'Data wydruku',
            observer: 'Badający',
            measurement: 'Pomiar',
            value: 'Wartość',
            nac: 'Kompleks brodawka-otoczka',
            uc: 'Górny biegun',
            lc: 'Fałd podpiersiowy',
            custom: 'Własne pomiary',
            adjustImagePos: 'Dostosuj pozycję zdjęcia',
            comment: 'Komentarz',
            return: 'Wróć',
            print: 'Drukuj'
        },
        measurements: {
            aap: "Różnica wysokości A - A'",
            a2medial: "Odległość A - linia pośrodkowa",
            ap2medial: "Odległość A' - linia pośrodkowa",
            a2inc: "Odległość A - SN",
            ap2inc: "Odległość A' - SN",
            b2c: "Odległość B - C",
            bp2cp: "Odległość B' - C'",
            d2e: "Odległość D - E",
            dp2ep: "Odległość D' - E'",
            ucrucl: "Różnica wysokości wierzchołek prawy - wierzchołek lewy",
            ucr2a: "Odległość wierzchołek prawy - A",
            ucl2ap: "Odległość wierzchołek lewy - A'",
            ucr2aucl2ap: "Różnica",
            lcrlcl: "Różnica wysokości wierzchołek prawy - wierzchołek lewy",
            lcr2medial: "Odległość wierzchołek prawy - linia pośrodkowa",
            lcl2medial: "Odległość wierzchołek lewy - linia pośrodkowa",
            lcr2mediallcl2medial: "Różnica",
            lcr2a: "Odległość wierzchołek prawy - A",
            lcl2ap: "Odległość wierzchołek lewy - A'",
            lcr2alcl2ap: "Różnica",

        },
        pointNames: {
            0: 'R1',
            1: 'R2',
            2: "R1'",
            3: "R2'",
            4: `R1"`,
            5: `R2"`,
            6: "A|Boczny",
            7: "B|Przyśrodkowy",
            8: "C|Wierzchołek",
            9: "D|Boczny - krzywa",
            10: "E|Wierzchołek - krzywa boczna",
            11: "F|Przyśrodkowy - krzywa",
            12: "G|Wierzchołek - krzywa przyśrodkowa",
            13: "A'|Boczny",
            14: "B'|Przyśrodkowy",
            15: "C'|Wierzchołek",
            16: "D'|Boczny - krzywa",
            17: "E'|Wierzchołek - krzywa boczna",
            18: "F'|Przyśrodkowy - krzywa",
            19: "G'|Wierzchołek - krzywa przyśrodkowa",
            20: "A|Góra",
            21: "B|Dół",
            22: "C|Wierzchołek",
            23: "D|Góra - krzywa",
            24: "E|Wierzchołek - krzywa górna",
            25: "F|Dół - krzywa",
            26: "G|Wierzchołek - krzywa dolna",
            27: "A'|Góra",
            28: "B'|Dół",
            29: "C'|Wierzchołek",
            30: "D'|Góra - krzywa",
            31: "E'|Wierzchołek - krzywa górna",
            32: "F'|Dół - krzywa",
            33: "G'|Wierzchołek - krzywa dolna",
        },
        cookies: {
            textA: "Nasza strona używa plików cookie by usprawnić jakość użytkowania serwisu i pozwolić nam optymalizować nasze usługi.",
            textB: "Poprzez dalsze przeglądanie zgadzasz się na używanie plików cookie. Możesz zapoznać się z naszą ",
            pp: "Polityką Prywatności i Polityką plików Cookie ",
            here: "tutaj.",
            continue: "Rozumiem"
        },
    },
    {
        mainApp: {
            loadImageInfo: 'Load a photograph in the left panel'
        },
        sideDrawer: {
            maleVolumeDescription: `The male BIVE module (BIVE-M) is dedicated for male breast volume estimation based on photographs. The module can be used for scientific and clinical purposes (e.g. to monitor pharmacological therapies) in men with enlarged breasts. It considers the difference between female and male chest and breasts: pectoralis muscle eminence, limited area of gland presence in men (usually in central part of the breast, not between lateral sternal line and anterior axillary line like in women). However in cases when male chest/breasts present typical features for female breasts (the area of breast and ptosis – inframammary fold presence) – the module for female breast (BIVE ) should be used. 

            Validation for the module based on transsexuals on different stages of hormonotherapy was based on the best-match use of modules. (Available in the paper: under review)`,
            backText: 'Back to menu',
            appTitle: 'Volume Estimator',
            appTitleMale: 'Volume Estimator - M',
            useChromeSuggestion: 'For best user experience, we recommend running the application in Google Chrome.',
            validationHere: 'Article presenting validation currently under review',
            automaticProgression: 'Automatic progression',
            copyReferenceLineInput: "All reference lines are the same length",
            showAnimatedInstructions: 'Show animated instructions while adding points',
            sections: {
                photoLoad: 'Load photographs',
                photoEdit: 'Edit photographs',
                referenceLines: 'Reference lines',
                sagittalXsection: 'Sagittal Cross-Section',
                transverseXsection: 'Transverse Cross-Section',
                report: 'Report'

            },
            loadRightPhoto: "Load the patient's right side photograph:",
            loadLeftPhoto: "Load the patient's left side photograph:",
            loadFrontPhoto: "Load the patient's front photograph:",
            skipPhotoEdit: "Skip photograph editing",
            rightImageEdit: 'Edit right side photograph',
            leftImageEdit: 'Edit left side photograph',
            frontImageEdit: 'Edit front photograph',
            brightness: 'Brightness',
            contrast: 'Contrast',
            saturation: 'Saturation',
            copyMainImageValues: 'Copy the currently edited photograph values',
            fixRotation: 'Correct rotated photograph',
            fixSuppRotation: 'Match supplementary image rotation',
            fixInstructionMain: 'Mark two characteristic points on the main photograph',
            fixInstructionSupp: 'In the same order, mark the points on the supplementary photograph',
            edit: 'Edit',
            next: 'Next',
            point: 'Point',
            line: 'Line segment',
            adjustRotation: 'Match',
            refLineHeadingRight: 'Mark the reference line segment on the right side photograph',
            refLineHeadingLeft: 'Mark the reference line segment on the left side photograph',
            refLineHeadingFront: 'Mark the reference line segment on the front photograph',
            refLineInstruction: "Place a point on each end of the line segment. Next provide the length of the segment in centimetres.",
            sagittalXsectionHeading: "Mark the breast outlines on the sagittal plane",
            sagittalXsectionInstructionRight: "Mark the outline of the right breast by placing points consecutively: on the upper pole, on the inframammary fold, on the nipple. Next, adjust the orange control points so that the curve outlines the breast.",
            sagittalXsectionInstructionLeft: "Mark the outline of the left breast by placing points consecutively: on the upper pole, on the inframammary fold, on the nipple. Next, adjust the orange control points so that the curve outlines the breast.",
            transverseXsectionHeading: "Mark the breast outlines on the transverse plane",
            transverseXsectionMaleInstruction: {
                heading: "When marking lateral and medial borders of the breast and the point of maximum eminence of the breast, please follow the rules below:",
                point1: "1. Mark the borders of the breast (fatty tissue and/or gland), focusing only on the eminence lying over the background (pectoralis muscle and general layer of fatty tissue covering the chest).",
                point2: "2. If you are not sure about identifying the medial and lateral borders of the eminence on the photograph, you can make it easier by making points at the borders on patients’ body during direct examination before taking the photographs.",
                point3: "3. On the lateral borders, also try to outline the eminence that outstands over the “background”."
            },
            transverseXsectionRightInstruction: "Mark the outline of the right breast by placing points on the widest cross-section of the breast, starting from the lateral boundary. Next, adjust the orange control points so that the curve outlines the chosen cross-section.",
            transverseXsectionLeftInstruction: "Mark the outline of the left breast by placing points on the widest cross-section of the breast, starting from the lateral boundary. Next, adjust the orange control points so that the curve outlines the chosen cross-section.",
            rightSide: 'Right side',
            leftSide: 'Left side',
            topBorderInstructions: 'Outline the upper pole of each breast, placing points according to corresponding visual guides.',
            infraMammaryInstructions: 'Outline the inframammary fold of each breast, placing points according to corresponding visual guides.',
            addCustomPoints: 'Custom points:',
            addCustomLines: 'Custom line segments:',
            measurement: 'Measurement',
            value: 'Value',
            breast: 'Breast',
            volume: 'Volume',
            rightBreast: 'Right',
            leftBreast: 'Left',
            selectAll: 'Select all',
            customMeasurements: 'Custom measurements',
            customMeasurementDisclaimer: `Warning! The application was validated on measurements listed in the table above.
            Custom measurements will be mathematically correct, but may not correspond to physical measurements. Please treat them with limited trust.`,
            hidePointNames: 'Hide point names',
            hideReferenceLines: 'Hide reference lines',
            hideAllObjects: 'Hide all',
            openReport: "Open report",
            addingPoint: 'Adding points:',
            movingPoint: 'Moving points:',
            sagittal: 'Sagittal',
            transverse: 'Transverse',
            hide: 'Hide',
            show: 'Show',
            chooseAlgorithm: 'Choose active algorithm:',
            sagittalAlgorithm: 'sagittal cross-secrion prioritisation',
            transverseAlgorithm: 'transverse cross-section prioritisation',
            showModels: 'Show models:',
            vizInfo: `A window with three dimensional models of the breasts will be displayed.
            Considering the correspondence of the models' shapes to real shapes, the curves on the photographs can be adjusted accordingly`,
            reportTableInfo: {
                patientData: 'Patient data',
                name: "Fist name",
                surname: "Last name",
                dateOfBirth: 'Date of birth',
                sex: "Sex",
                female: 'Female',
                male: 'Male',
                assessorData: 'Doctor data',
                assessorId: 'ID / First name & last name',
                comment: 'Commentary',
                choose: 'Choose'
            },
            customPointBox: {
                image1: 'Main photograph',
                image2: 'Supplementary photograph'
            },
            customLineBox: {
                point1: 'Point 1:',
                point2: 'Point 2:',
                choose: 'Choose'
            },
            customMeasurement: {
                distance: 'Distance',
                levelDifference: 'Level difference',
                distanceToMedial: 'Distance to medial line',
                choose: 'Choose'
            },
            loadAll: 'Load all',
            registration: {
                customPointsAndLines: 'Custom points and line segments available only for registered users',
                haveAccount: 'Already got an account?',
                signUp: 'Sign Up',
                signIn: 'Sign In',
                customMeasurements: 'Custom measurements available only for registered users'
            },
            sendData: "Save measurement",
            startTutorialMode: "Start Tutorial",
            tutorialPointFeedback: "Point position feedback:",
            tutorialPointInstruction: "Move the points according to arrow directions untill you see a ✔",
            tutorialRightBreast: "Right breast:",
            tutorialLeftBreast: "Left breast:",
            tutorialPoint: "Point",
            tutorialPointDescription: "Description",
            upOrDown: "Up / Down",
            frontOrBack: "Front / Back",
            backOrFront: "Back / Front",
            lateralOrMedial: "Lateral / Medial",
            medialOrLateral: "Medial / Lateral",
            yourResult: "Your result",
            idealResult: "Correct value"
        },
        firstRunModal: {
            welcome: 'Welcome to BreastIdea - Volume Estimator.',
            firstTime: "We noticed it's your first time here, so we encourage you to take a look at the manual.",
            showInstruction: 'Show manual',
            clicking: 'Clicking on ',
            goBack: 'will bring back the manual.',
            tutorial: "The application will run in tutorial mode, so you can learn how to place points correctly. Guides will be displayed in appropriate sections in the left panel.",
            startApp: 'Start the tutorial',
            termsCb: [
                "I have read and agree to the ",
                "Terms of Service",
                " of BreastIdea - Volume Estimator Application.",
            ],
            termsNeeded: "Accepting the Terms of Service is necessary to start using the application.",
            hideTerms: 'Hide Terms of Service',
            terms: [
                "BreastIdea - Volume Estimator is an web application provided by the BreastIdea Website.",
                "By using the application, the user is obliged to comply with the General Terms of Service of the Website and those listed in this document. If you do not accept these terms, you will not be able to use the application.",
                "The application is an auxiliary tool. The user uses it at their own risk, becoming acquainted with the validation process, from which they can get information on the accuracy and precision of the obtained results and their correlation with the actual measurements.",
                "The developers are not responsible for the results obtained in the application and relinquish responsibility for the results obtained through the incorrect use of the application or errors arising as a result of technical problems on the part of the user or third parties, that is, the hosting service providers.",
            ]

        },
        tutorialStrings: {
            bannerPrimary: "You are in Tutorial Mode. You will see feedback on point placement in appropriate sections.",
            bannerSecondary: "You can always start the Tutorial Mode by clicking the Run Tutorial button in the left panel.",
            endTutorial: "End tutorial",
            
        },
        instructions: {
            title: 'Volume Estimator',

            interface: {
                header: 'Interface',
                text: {
                    leftPanel: 'The panel on the left side of the screen contains six sections.',
                    eachSection: 'Each section consists of instructions and buttons, guiding the user through breast measurements.',
                    rightPanel: 'The panel on the right side of the screen contains the photographs supplied by the user and a menu.'
                }
            },
            usage: {
                header: 'Usage',
                text: {
                    inPointMode: 'In point mode',
                    addPoint: `left clicking on the photo adds a marker. Left clicking and dragging on an existing point allows to move it around.`,
                    inMoveMode: 'In framing mode',
                    move: 'left clicking and dragging allows to change position of the photograph.',
                    middleMove: 'While the photograph is in context, clicking and dragging with the mousewheel (middle mouse button) allows to move the photograph regardless of mode.',
                    inScaleMode: 'In zoom mode',
                    scale: 'left clicking and dragging allows to change image zoom level',
                    middleScale: 'While the photograph is in context, scrolling with the mousewheel zooms the image in and out regardless of mode.',
                    rmb: 'Right clicking the photograph opens up a menu with additional options.'
                }
            },
            howToUse: {
                header: 'How to use the application',
                text: {
                    load: "Load the patient's photographs (front, right side, left side)",
                    edit: "Optionally correct each photograph's brightness, contrast and saturation",
                    refLine: "Mark the reference line segment on each photograph and supply its real length in centimetres",
                    markers: "Follow instructions to place all the indicated markers",
                    table: "A dynamically updated table of measurements is found in the last section. You can choose which measurements are to be included in the report",
                    algorithm: "Choose the preferred algorithm used for calculation (discussed later)",
                    form: "You can provide patient and doctor data, as well as a field to write a commentary",
                    fixPhoto: "Adjust the photographs (position, zoom) the way you want them to be displayed in the report",
                    clickBtn: "Click",
                    report: "Report",
                    toOpen: "to open a print-ready, dynamic report.",
                    reportFix: "Before print, you can adjust the point of interest of included photographs",
                    press: "Click",
                    print: "Print",
                    toPrint: "to print the report as a physical copy or PDF file (browser specific)",
                    click: "Click",
                    return: "Back",
                    toReturn1: "to return to the application. Next in the section ",
                    toReturn2: "Load photographs",
                    toReturn3: " click ",
                    toReturn4: "Reset",
                    toReturn5: " to start anew.",
                }
            },
            interfaceDiscussion: {
                header: 'Interface discussion',
            },


            sideDrawer: {
                header: 'Left panel',
                subtitles: {
                    showAnim: 'Show animated instructions while adding points',
                    load: 'Load photographs',
                    edit: 'Edit photographs',
                    ref: 'Reference lines',
                    sagittalXsection: 'Sagittal Cross-Section',
                    transverseXsection: 'Transverse Cross-Section',
                    report: 'Report',
                    algorithm: 'Algorithm',
                    viz: 'Visualisation'
                },
                text: {
                    autoRun: 'when all markers are placed, the next section is automatically started',
                    showAnim: 'a simple visual guideline is shown when adding new points',
                    load: 'a file browser is opened on click. Choose the photograph of the patient. Allowed file formats: .jpg, .png, .bmp, .gif.',
                    edit: `to better recognize anatomical landmarks on the patient's body, the image's brightness, contrast and saturation can be adjusted.`,
                    ref: `a line segment of known length needs to be present on the patient's body.
                    Marking it and providing its real length makes it possible to recalculate pixels in the photo to SI units. Checking the automatic progression option speeds up the process by automatically switching photographs when necessary markers are added.`,
                    sagittalXsection: `mark the outline of each breast by placing points consecutively: on the upper pole, on the inframammary fold, on the nipple. Next, adjust the orange control points so that the curve outlines the breast.`,
                    transverseXsection: `mark the outline of the right breast by placing points on the widest cross-section of each breast, starting from the lateral boundary. Next, adjust the orange control points so that the curve outlines the chosen cross-section.`,
                    report: 'see the results of the curve-based calculations. You can change the algorithm, display three dimensional breast models and print a medical documentation ready report',
                    algorithm: "in most cases the Sagittal Algorithm is preferred, as the side photograph contours are easier to mark. However, sometimes finding the proper side outline is impossible. Thus, the Transverse Algorithm can be used. The downside is that it needs proper transverse cross-section marking (what can be challenging for new users).",
                    viz: "to facilitate learning of outline marking (especially the transverse cross section), you can juxtapose generated three dimensional estimates with the real breast shapes. If the correspondence is low, it is worth modifying the curves to make the shape more accurate.",
                    vizMovement: [
                        "To move around the Visualisation window:",
                        "Click and hold the left mouse button to rotate the camera.",
                        "Click and hold the right mouse button to move the camera.",
                        "Use the mouse wheel to zoom in or out."
                    ]
                }
            },
            photoArea: {
                header: 'Right pannel',
                text: {
                    point: 'point mode. When this mode is active, you can add and move points.',
                    move: 'framing mode. When this mode is active, you can move the image to place markers more precisely.',
                    scale: 'zoom mode. When this mode is active, you can zoom into and out of the image',
                    switch: 'change the currently displayed photograph. Unavailable when adding points.',
                    manual: 'show the instruction manual.'
                }
            },
            contextMenu: {
                header: 'Context menu',
                subtitles: {
                    reset: 'Reset image',
                    coords: 'Show point coordinates',
                    fps: 'FPS: '
                },
                text: {
                    reset: 'revert the image translation and scale back to initial values.',
                    coords: 'name and position of the currently selected point (orange stroke) will be shown in the top right hand corner of the image.',
                    fps: `adjust how often the image refreshes. 
Low values mean less fluid movement of points and images, but offer less performace load. Minimum 5, maximum 60 frames per second.`
                }
            },

        },
        photoArea: {
            nacInstructions: {
                nipple: 'Nipple: ',
                point: 'Point: ',
                right: 'Right',
                left: 'Left'
            },
            contextMenu: {

                reset: 'Reset image position',
                show: 'Show',
                hide: 'Hide',
                pointCoordinates: ' point coordinates',
                fps: 'FPS: '
            },
        },

        footer: {
            localComputation: `The application does not store or save sensitive data.
            Performance-heavy calculations are conducted on our server, while all photographs remain local on user's device.`,
            ToS: `General Terms of Service`,
            appToS: `Volume Estimator Terms of Service`,
            PP: `Privacy Policy`,
            contact: `Contact`
        },
        printPage: {
            title: 'Quick assessment',
            patientData: 'Patient data',
            name: 'First name',
            surname: 'Last name',
            sex: 'Sex',
            female: 'Female',
            male: 'Male',
            dateOfBirth: 'Date of birth',
            printDate: 'Print date',
            observer: 'Doctor',
            measurement: 'Measurement',
            value: 'Value',
            nac: 'Nipple-areolar complex',
            uc: 'Upper pole',
            lc: 'Inframammary fold',
            custom: 'Custom measurements',
            adjustImagePos: 'Adjust image position',
            comment: 'Commentary',
            return: 'Back',
            print: 'Print'
        },
        measurements: {
            aap: "Level difference A - A'",
            a2medial: "Distance A - medial line",
            ap2medial: "Distance A' - medial line",
            a2inc: "Distance A - SN",
            ap2inc: "Distance A' - SN",
            b2c: "Distance B - C",
            bp2cp: "Distance B' - C'",
            d2e: "Distance D - E",
            dp2ep: "Distance D' - E'",
            ucrucl: "Apex levels difference",
            ucr2a: "Distance ∩A - A",
            ucl2ap: "Distance ∩A' - A'",
            ucr2aucl2ap: "Difference",
            lcrlcl: "Apex levels difference",
            lcr2medial: "Distance ∪A - medial line",
            lcl2medial: "Distance ∪A' - medial line",
            lcr2mediallcl2medial: "Difference",
            lcr2a: "Distance ∪A - A",
            lcl2ap: "Distance ∪A' - A'",
            lcr2alcl2ap: "Difference",

        },
        pointNames: {
            0: 'R1',
            1: 'R2',
            2: "R1'",
            3: "R2'",
            4: `R1"`,
            5: `R2"`,
           6: "A|Lateral",
            7: "B|Medial",
            8: "C|Apex",
            9: "D|Lateral - curve",
            10: "E|Apex - lateral curve",
            11: "F|Medial - curve",
            12: "G|Apex - medial curve",
            13: "A'|Lateral",
            14: "B'|Medial",
            15: "C'|Apex",
            16: "D'|Lateral - curve",
            17: "E'|Apex - lateral curve",
            18: "F'|Medial - curve",
            19: "G'|Apex - medial curve",
            20: "A|Top",
            21: "B|Bottom",
            22: "C|Apex",
            23: "D|Top - curve",
            24: "E|Apex - top curve",
            25: "F|Bottom - curve",
            26: "G|Apex - bottom curve",
            27: "A'|Top",
            28: "B'|Bottom",
            29: "C'|Apex",
            30: "D'|Top - curve",
            31: "E'|Apex - top curve",
            32: "F'|Bottom - curve",
            33: "G'|Apex - bottom curve",
        },
        cookies: {
            textA: "Our website uses cookies to make your experience smoother and allow us to optimise our service.",
            textB: "By continuing to browse this website you accept the use of cookies. Read more about our ",
            pp: "Privacy Policy and Cookie Policy ",
            here: "here.",
            continue: "OK"
        },
    },

]