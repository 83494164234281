import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import axios from 'axios'
import './index.css';
//import registerServiceWorker from './registerServiceWorker';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faRuler,
    faPrint,
    faLightbulb,
    faCheck,
    faArrowCircleLeft,
    faArrowCircleRight,
    faUserCircle,
    faSortDown,
    faGlobe,
    faCogs,
    faPencilRuler,
    faHome,
    faTh,
    faChevronDown,
    faChevronUp,
    faFile,
    faFileImage,
    faCrosshairs,
    faArrowsAlt,
    faExpandArrowsAlt,
    faSyncAlt,
    faQuestionCircle,
    faCheckDouble,
    faTrashAlt,
    faExchangeAlt,
    faTimes,
    faFolder,
    faFolderOpen,
    faSave,
    faPlusCircle,
    faBookmark,
    faArrowRight,
    faArrowLeft,
    faArrowUp,
    faLock

} from '@fortawesome/free-solid-svg-icons'

import App from './App'
import authReducer from './store/reducers/auth'
import quickAssessmentReducer from './store/reducers/quickAssessment'
import volume2dReducer from './store/reducers/volume2d'

library.add(fab,
    faRuler,
    faPrint,
    faLightbulb,
    faCheck,
    faArrowCircleLeft,
    faArrowCircleRight,
    faUserCircle,
    faSortDown,
    faGlobe,
    faCogs,
    faPencilRuler, 
    faHome, 
    faTh, 
    faChevronDown, 
    faChevronUp,
    faFile,
    faFileImage,
    faCrosshairs,
    faArrowsAlt,
    faExpandArrowsAlt,
    faSyncAlt,
    faQuestionCircle,
    faCheckDouble,
    faTrashAlt,
    faExchangeAlt,
    faTimes,
    faFolder,
    faFolderOpen,
    faSave,
    faPlusCircle,
    faBookmark,
    faArrowRight,
    faArrowLeft,
    faArrowUp,
    faLock
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    quickAssessment: quickAssessmentReducer,
    volume2d: volume2dReducer

})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));


if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'){
    axios.defaults.baseURL = ''
 }else{
    axios.defaults.baseURL = 'https://breastidea.com'
 }




const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));
//registerServiceWorker();
