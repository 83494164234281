
let pointNames = {

    0: {
        id: 'ref1a',
        name: "R1", color: "#2D2D2D", tutorialName: ''
    },
    1: {
        id: 'ref1b',
        name: "R2", color: "#2D2D2D", tutorialName: ''
    },
    2: {
        id: 'ref2a',
        name: "R1'", color: "#2D2D2D", tutorialName: ''
    },
    3: {
        id: 'ref2b',
        name: "R2'", color: "#2D2D2D", tutorialName: ''
    },
    4: {
        id: 'ref3a',
        name: `R1"`, color: "#2D2D2D", tutorialName: ''
    },
    5: {
        id: 'ref3b',
        name: `R2"`, color: "#2D2D2D", tutorialName: ''
    },

    6: {
        id: 'right_front_lateral',
        name: "(", color: "#0095e0", right: true, tutorialName: 'A'
    },
    7: {
        id: 'right_front_medial',
        name: ")", color: "#0095e0", right: true, tutorialName: 'B'
    },
    8: {
        id: 'right_front_nipple',
        name: "o", color: "#0095e0", right: true, tutorialName: 'C'
    },
    9: {
        id: 'right_lateral_ctrl_a',
        name: "", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: 'D'
    },
    10: {
        id: 'right_lateral_ctrl_b',
        name: "", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: 'E'
    },
    11: {
        id: 'right_medial_ctrl_a',
        name: "'", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: 'F'
    },
    12: {
        id: 'right_medial_ctrl_b',
        name: "'", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: 'G'
    },
    13: {
        id: 'left_front_lateral',
        name: ")'", color: "#e0008d", tutorialName: "A'"
    },
    14: {
        id: 'left_front_medial',
        name: "('", color: "#e0008d", tutorialName: "B'"
    },
    15: {
        id: 'left_front_nipple',
        name: "o'", color: "#e0008d", tutorialName: "C'"
    },
    16: {
        id: 'left_lateral_ctrl_a',
        name: "", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "D'"
    },
    17: {
        id: 'left_lateral_ctrl_b',
        name: "", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "E'"
    },
    18: {
        id: 'left_medial_ctrl_a',
        name: "'", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "F'"
    },
    19: {
        id: 'left_medial_ctrl_b',
        name: "'", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "G'"
    },
    20: {
        id: 'right_top',
        name: "^", color: "#0095e0",  right: true, tutorialName: "A"
    },
    21: {
        id: 'right_bottom',
        name: "v", color: "#0095e0", right: true, tutorialName: "B"
    },
    22: {
      id: 'right_nipple',
        name: ">", color: "#0095e0",  right: true, tutorialName: "C"
    },
    23: {
        id: 'right_top_ctrl_a',
        name: "", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "D"
    },
    24: {
        id: 'right_top_ctrl_b',
        name: "", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "E"
    },
    25: {
        id: 'right_bottom_ctrl_a',
        name: "'", color: "#d16b00",stroke: '#f4f4f4', controlPoint: true, tutorialName: "F"
    },
    26: {
        id: 'right_bottom_ctrl_b',
        name: "'", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "G"
    },
    27: {
        id: 'left_top',
        name: "^", color: "#e0008d", tutorialName: "A'"
    },
    28: {
        id: 'left_bottom',
        name: "v", color: "#e0008d",tutorialName: "B'"
    },
    29: {
        id: 'left_nipple',
        name: "<", color: "#e0008d", tutorialName: "C'"
    },
    30: {
        id: 'left_top_ctrl_a',
        name: "", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "D'"
    },
    31: {
        id: 'left_top_ctrl_b',
        name: "", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "E'"
    },
    32: {
        id: 'left_bottom_ctrl_a',
        name: "'", color: "#d16b00",stroke: '#f4f4f4', controlPoint: true, tutorialName: "F'"
    },
    33: {
        id: 'left_bottom_ctrl_b',
        name: "'", color: "#d16b00", stroke: '#f4f4f4', controlPoint: true, tutorialName: "G'"
    },
   
}

let pointNumbers = {}

Object.keys(pointNames).forEach(key => {
    pointNumbers[pointNames[key].id] = parseInt(key)

})


export { pointNames, pointNumbers }


export const points = new Array(Object.keys(pointNumbers).length).fill(null)


// export const Point = () => {
//     this.x = 0;
//     this.y = 0;
//     this.r = 4;
//     this.sAngle = 0;
//     this.eAngle = 2 * Math.PI;
//     this.fill = '#000000';
//     this.stroke = '';
//     this.name = '';
//     this.image = 0;
//     this.controlPoint = false;
// }

export const addPoint = (x, y, r, fill, stroke, name, tutorialName, image, index, isControlPoint) => {
    var point = {};
    point.x = x;
    point.y = y;
    point.r = r;
    point.sAngle = 0;
    point.eAngle = 2 * Math.PI;
    point.fill = fill;
    point.stroke = stroke;
    point.name = name;
    point.tutorialName = tutorialName;
    point.image = image;
    point.controlPoint = isControlPoint
    points.splice(index, 1, point);
}


export const drawPoint = (ctx, point, showText, drawReferenceLines, currentSubsection, rotation, scale, tutorialMode) => {
    if (!drawReferenceLines) {
        if (point.name === pointNames[pointNumbers['ref1a']].name) {
            return;
        } else if (point.name === pointNames[pointNumbers['ref1b']].name) {
            return;
        } else if (point.name === pointNames[pointNumbers['ref2a']].name) {
            return;
        } else if (point.name === pointNames[pointNumbers['ref2b']].name) {
            return;
        }else if (point.name === pointNames[pointNumbers['ref3a']].name) {
            return;
        }else if (point.name === pointNames[pointNumbers['ref3b']].name) {
            return;
        }

    }
    if (currentSubsection !== 'rotationAdjust' && point.adjustment) {
        return
    }

    ctx.beginPath();
    ctx.arc(point.x, point.y, Math.max(2,point.r/scale), point.sAngle, point.eAngle);
    ctx.fillStyle = point.fill;
    ctx.fill();
    if (point.stroke !== 'none') {
        ctx.strokeStyle = point.stroke
        ctx.lineWidth = Math.max(1,2/scale);
        ctx.stroke()
    }
    if (showText) {
        ctx.save()
        ctx.translate(point.x, point.y)
        ctx.rotate(-rotation)
        ctx.font = "bold " + (Math.max(8,(11+point.r)/scale)) + "px Arial";
        ctx.fillText(point.name,0+point.r, 0 - point.r);
        if(tutorialMode){
            ctx.fillStyle = 'black'
            ctx.strokeStyle = 'white'
            ctx.lineWidth = 2;
            ctx.strokeText(point.tutorialName, 0+Math.max(point.r, point.r / scale), 0 + Math.max(point.r, point.r*3 / scale));
            ctx.fillText(point.tutorialName, 0+Math.max(point.r, point.r / scale), 0 + Math.max(point.r, point.r*3 / scale))

        } 
        ctx.restore()

    }


}

// export const Line = () => {
//     this.point1 = null;
//     this.point2 = null;
//     this.dash = [8, 3];
//     this.width = 3;
//     this.image = 0;
// }

export const lineArrayGenerator = {

}


export const addLine = (point1, point2, dash, width) => {
    var line = {};
    line.point1 = point1;
    line.point2 = point2;
    line.dash = dash;
    line.width = width;
    return line
}

export const drawLine = (ctx, line, drawReferenceLines, currentSubsection, scale) => {
    if ((!drawReferenceLines && line.point1 === pointNumbers['ref1a']) || (!drawReferenceLines && line.point1 === pointNumbers['ref2a']) || (!drawReferenceLines && line.point1 === pointNumbers['ref3a'])) {
        return;
    }

    const point1 = points[line.point1]
    const point2 = points[line.point2]



    if (point1 && point2) {
        line.image = point1.image

        const gradient = ctx.createLinearGradient(point1.x, point1.y, point2.x, point2.y);
        gradient.addColorStop("0", point1.fill === '#2D2D2D' ? '#2D2D2D' : '#f3f3f3');
        gradient.addColorStop("1.0", point2.fill);

        ctx.strokeStyle = gradient
        ctx.setLineDash(line.dash);
        ctx.lineWidth = Math.max(2,point1.r/scale);
        ctx.beginPath();
        ctx.moveTo(point1.x, point1.y);
        ctx.lineTo(point2.x, point2.y);
        ctx.stroke();
        ctx.setLineDash([]);
    }
}

export const lines = [
    addLine(pointNumbers['ref1a'], pointNumbers['ref1b'], [8, 3], 3),
    addLine(pointNumbers['ref2a'], pointNumbers['ref2b'], [8, 3], 3),
    addLine(pointNumbers['ref3a'], pointNumbers['ref3b'], [8, 3], 3),

    addLine(pointNumbers['right_front_lateral'], pointNumbers['right_lateral_ctrl_a'], [8, 3], 3),
    addLine(pointNumbers['right_front_nipple'], pointNumbers['right_lateral_ctrl_b'], [8, 3], 3),

    addLine(pointNumbers['right_front_medial'], pointNumbers['right_medial_ctrl_a'], [8, 3], 3),
    addLine(pointNumbers['right_front_nipple'], pointNumbers['right_medial_ctrl_b'], [8, 3], 3),

    addLine(pointNumbers['left_front_lateral'], pointNumbers['left_lateral_ctrl_a'], [8, 3], 3),
    addLine(pointNumbers['left_front_nipple'], pointNumbers['left_lateral_ctrl_b'], [8, 3], 3),

    addLine(pointNumbers['left_front_medial'], pointNumbers['left_medial_ctrl_a'], [8, 3], 3),
    addLine(pointNumbers['left_front_nipple'], pointNumbers['left_medial_ctrl_b'], [8, 3], 3),

    addLine(pointNumbers['right_top'], pointNumbers['right_top_ctrl_a'], [8, 3], 3),
    addLine(pointNumbers['right_nipple'], pointNumbers['right_top_ctrl_b'], [8, 3], 3),

    addLine(pointNumbers['right_bottom'], pointNumbers['right_bottom_ctrl_a'], [8, 3], 3),
    addLine(pointNumbers['right_nipple'], pointNumbers['right_bottom_ctrl_b'], [8, 3], 3),

    addLine(pointNumbers['left_top'], pointNumbers['left_top_ctrl_a'], [8, 3], 3),
    addLine(pointNumbers['left_nipple'], pointNumbers['left_top_ctrl_b'], [8, 3], 3),

    addLine(pointNumbers['left_bottom'], pointNumbers['left_bottom_ctrl_a'], [8, 3], 3),
    addLine(pointNumbers['left_nipple'], pointNumbers['left_bottom_ctrl_b'], [8, 3], 3),
]


// export const Curve = () => {
//     this.start = null;
//     this.end = null;
//     this.cp1 = null;
//     this.cp2 = null;
//     this.colour = 'black';

// }
export const addCurve = (start, end, cp1, cp2, colour) => {
    var curve = {};
    curve.start = start;
    curve.end = end;
    curve.cp1 = cp1;
    curve.cp2 = cp2;
    curve.colour = colour;
    return curve;
}

export const drawCurve = (ctx, curve, scale) => {
    const start = points[curve.start]
    const end = points[curve.end]
    const cp1 = points[curve.cp1]
    const cp2 = points[curve.cp2]


    if (start && end && cp1 && cp2) {
        const r = start.r
        ctx.setLineDash([]);
        ctx.beginPath();
        ctx.lineWidth = Math.max(2,r/scale);
        ctx.strokeStyle = curve.colour;
        ctx.moveTo(start.x, start.y);
        ctx.bezierCurveTo(cp1.x , cp1.y , cp2.x, cp2.y, end.x, end.y);
        ctx.stroke();
    }


}

export const curves = [
    addCurve(pointNumbers['right_front_lateral'], pointNumbers['right_front_nipple'], pointNumbers['right_lateral_ctrl_a'], pointNumbers['right_lateral_ctrl_b'], "#0095e0"),
    addCurve(pointNumbers['right_front_medial'], pointNumbers['right_front_nipple'], pointNumbers['right_medial_ctrl_a'], pointNumbers['right_medial_ctrl_b'], "#0095e0"),
    addCurve(pointNumbers['left_front_lateral'], pointNumbers['left_front_nipple'], pointNumbers['left_lateral_ctrl_a'], pointNumbers['left_lateral_ctrl_b'], "#e0008d"),
    addCurve(pointNumbers['left_front_medial'], pointNumbers['left_front_nipple'], pointNumbers['left_medial_ctrl_a'], pointNumbers['left_medial_ctrl_b'], "#e0008d"),
    
    addCurve(pointNumbers['right_top'], pointNumbers['right_nipple'], pointNumbers['right_top_ctrl_a'], pointNumbers['right_top_ctrl_b'], "#0095e0"),
    addCurve(pointNumbers['right_bottom'], pointNumbers['right_nipple'], pointNumbers['right_bottom_ctrl_a'], pointNumbers['right_bottom_ctrl_b'], "#0095e0"),
    addCurve(pointNumbers['left_top'], pointNumbers['left_nipple'], pointNumbers['left_top_ctrl_a'], pointNumbers['left_top_ctrl_b'], "#e0008d"),
    addCurve(pointNumbers['left_bottom'], pointNumbers['left_nipple'], pointNumbers['left_bottom_ctrl_a'], pointNumbers['left_bottom_ctrl_b'], "#e0008d"),
]

