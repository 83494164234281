

let pointNames = {
    0: {
        id: 'adjustRotation1a',
        name: "1", color: "#2D2D2D", adjust: true
    },
    1: {
        id: 'adjustRotation1b',
        name: "2", color: "#2D2D2D", adjust: true
    },
    2: {
        id: 'adjustRotation2a',
        name: "1'", color: "#2D2D2D", adjust: true
    },
    3: {
        id: 'adjustRotation2b',
        name: "2'", color: "#2D2D2D", adjust: true
    },
    4: {
        id: 'ref1a',
        name: "R1", color: "#2D2D2D"
    },
    5: {
        id: 'ref1b',
        name: "R2", color: "#2D2D2D"
    },
    6: {
        id: 'ref2a',
        name: "R1'", color: "#2D2D2D"
    },
    7: {
        id: 'ref2b',
        name: "R2'", color: "#2D2D2D"
    },
    8: {
        id: 'sn1',
        name: "SN", color: "#2D2D2D"
    },
    9: {
        id: 'umb1',
        name: "UMB", color: "#2D2D2D"
    },
    10: {
        id: 'sn2',
        name: "SN'", color: "#2D2D2D"
    },
    11: {
        id: 'umb2',
        name: "UMB'", color: "#2D2D2D"
    },
    12: {
        id: 'a',
        name: "A", color: "#0095e0", image: "rA", right: true
    },
    13: {
        id: 'b',
        name: "B", color: "#0095e0", image: "rB", right: true
    },
    14: {
        id: 'c',
        name: "C", color: "#0095e0", image: "rC", right: true
    },
    15: {
        id: 'd',
        name: "D", color: "#0095e0", image: "rD", right: true
    },
    16: {
        id: 'e',
        name: "E", color: "#0095e0", image: "rE", right: true
    },
    17: {
        id: 'ap',
        name: "A'", color: "#e0008d", image: "lA"
    },
    18: {
        id: 'bp',
        name: "B'", color: "#e0008d", image: "lB"
    },
    19: {
        id: 'cp',
        name: "C'", color: "#e0008d", image: "lC"
    },
    20: {
        id: 'dp',
        name: "D'", color: "#e0008d", image: "lD"
    },
    21: {
        id: 'ep',
        name: "E'", color: "#e0008d", image: "lE"
    },
    22: {
        id: 'ucrS',
        name: "∩ P", color: "#0095e0", image: "ucrS", right: true
    },
    23: {
        id: 'ucrE',
        name: "∩ K", color: "#0095e0", image: "ucrE", right: true
    },
    24: {
        id: 'ucrA',
        name: "∩ W", color: "#0095e0", image: "ucrA", right: true
    },
    25: {
        id: 'uclS',
        name: "∩ P'", color: "#e0008d", image: "uclS"
    },
    26: {
        id: 'uclE',
        name: "∩ K'", color: "#e0008d", image: "uclE"
    },
    27: {
        id: 'uclA',
        name: "∩ W'", color: "#e0008d", image: "uclA"
    },
    28: {
        id: 'lcrS',
        name: "∪ P", color: "#0095e0", image: "lcrS", right: true
    },
    29: {
        id: 'lcrE',
        name: "∪ K", color: "#0095e0", image: "lcrE", right: true
    },
    30: {
        id: 'lcrA',
        name: "∪ W", color: "#0095e0", image: "lcrA", right: true
    },
    31: {
        id: 'lclS',
        name: "∪ P'", color: "#e0008d", image: "lclS"
    },
    32: {
        id: 'lclE',
        name: "∪ K'", color: "#e0008d", image: "lclE"
    },
    33: {
        id: 'lclA',
        name: "∪ W'", color: "#e0008d", image: "lclA"
    }
}

let pointNumbers = {}

Object.keys(pointNames).forEach(key => {
    pointNumbers[pointNames[key].id] = parseInt(key)

})


export { pointNames, pointNumbers }


export const points = new Array(Object.keys(pointNumbers).length).fill(null)


// export const Point = () => {
//     this.x = 0;
//     this.y = 0;
//     this.r = 4;
//     this.sAngle = 0;
//     this.eAngle = 2 * Math.PI;
//     this.fill = '#000000';
//     this.stroke = '';
//     this.name = '';
//     this.image = 0;
// }

export const addPoint = (x, y, r, fill, stroke, name, image, index, isAdjustmentPoint) => {
    var point = {};
    point.x = x;
    point.y = y;
    point.r = r;
    point.sAngle = 0;
    point.eAngle = 2 * Math.PI;
    point.fill = fill;
    point.stroke = stroke;
    point.name = name;
    point.image = image;
    point.adjustment = isAdjustmentPoint
    points.splice(index, 1, point);
}


export const drawPoint = (ctx, point, showText, drawReferenceLines, currentSubsection, rotation, scale) => {
    if (!drawReferenceLines) {
        if (point.name === pointNames[pointNumbers['ref1a']].name) {
            return;
        } else if (point.name === pointNames[pointNumbers['ref1b']].name) {
            return;
        } else if (point.name === pointNames[pointNumbers['ref2a']].name) {
            return;
        } else if (point.name === pointNames[pointNumbers['ref2b']].name) {
            return;
        }

    }
    if (currentSubsection !== 'rotationAdjust' && point.adjustment) {
        return
    }

    ctx.beginPath();
    ctx.arc(point.x, point.y, Math.max(2,point.r/scale), point.sAngle, point.eAngle);
    ctx.fillStyle = point.fill;
    ctx.fill();
    if (point.stroke !== 'none') {
        ctx.strokeStyle = point.stroke
        ctx.lineWidth = Math.max(1,2/scale);
        ctx.stroke()
    }
    if (showText) {
        ctx.save()
        ctx.translate(point.x, point.y)
        ctx.rotate(-rotation)
        ctx.font = "bold " + (Math.max(8,(11+point.r)/scale)) + "px Arial";
        ctx.fillText(point.name,0+point.r, 0 - point.r);
        ctx.restore()

    }

}

// export const Line = () => {
//     this.point1 = null;
//     this.point2 = null;
//     this.dash = [8, 3];
//     this.width = 3;
//     this.image = 0;
// }

export const lineArrayGenerator = {

}


export const addLine = (point1, point2, dash, width) => {
    var line = {};
    line.point1 = point1;
    line.point2 = point2;
    line.dash = dash;
    line.width = width;
    return line
}

export const drawLine = (ctx, line, drawReferenceLines, currentSubsection, scale) => {
    if ((!drawReferenceLines && line.point1 === pointNumbers['ref1a']) || (!drawReferenceLines && line.point1 === pointNumbers['ref2a'])) {
        return;
    }

    const point1 = points[line.point1]
    const point2 = points[line.point2]

    if (currentSubsection !== 'rotationAdjust' && point1.adjustment) {
        return
    }

    if (point1 && point2) {
        line.image = point1.image

        const gradient = ctx.createLinearGradient(point1.x, point1.y, point2.x, point2.y);
        gradient.addColorStop("0", point1.fill);
        gradient.addColorStop("1.0", point2.fill);

        ctx.strokeStyle = gradient
        ctx.setLineDash(line.dash);
        ctx.lineWidth = Math.max(2,point1.r/scale);
        ctx.beginPath();
        ctx.moveTo(point1.x, point1.y);
        ctx.lineTo(point2.x, point2.y);
        ctx.stroke();
        ctx.setLineDash([]);
    }
}

export const lines = [
    addLine(pointNumbers['adjustRotation1a'], pointNumbers['adjustRotation1b'], [8, 3], 3),
    addLine(pointNumbers['adjustRotation2a'], pointNumbers['adjustRotation2b'], [8, 3], 3),
    addLine(pointNumbers['ref1a'], pointNumbers['ref1b'], [8, 3], 3),
    addLine(pointNumbers['ref2a'], pointNumbers['ref2b'], [8, 3], 3),
    addLine(pointNumbers['sn1'], pointNumbers['umb1'], [8, 3], 3),
    addLine(pointNumbers['sn2'], pointNumbers['umb2'], [8, 3], 3),
    addLine(pointNumbers['a'], pointNumbers['ap'], [8, 3], 3),
]


// export const Curve = () => {
//     this.point1 = null;
//     this.point2 = null;
//     this.point3 = null;
//     this.fill = '#000000';
//     this.image = 0;
// }
export const addCurve = (point1, point2, point3, fill) => {
    var curve = {};
    curve.point1 = point1;
    curve.point2 = point2;
    curve.point3 = point3;
    curve.fill = fill;
    return curve;
}

export const drawCurve = (ctx, curve, scale) => {
    const point1 = points[curve.point1]
    const point2 = points[curve.point2]
    const point3 = points[curve.point3]

    if (point1 && point2 && point3) {
        curve.image = point1.image
        ctx.beginPath();
        ctx.moveTo(point1.x, point1.y);
        var cpX = 2 * point3.x - point1.x / 2 - point2.x / 2;
        var cpY = 2 * point3.y - point1.y / 2 - point2.y / 2;
        ctx.quadraticCurveTo(cpX, cpY, point2.x, point2.y);
        ctx.lineWidth = Math.max(2,point1.r/scale);
        ctx.strokeStyle = curve.fill;
        ctx.setLineDash([]);
        ctx.stroke();
    }


}

export const curves = [
    addCurve(pointNumbers['ucrS'], pointNumbers['ucrE'], pointNumbers['ucrA'], "#0095e0"),
    addCurve(pointNumbers['uclS'], pointNumbers['uclE'], pointNumbers['uclA'], "#e0008d"),
    addCurve(pointNumbers['lcrS'], pointNumbers['lcrE'], pointNumbers['lcrA'], "#0095e0"),
    addCurve(pointNumbers['lclS'], pointNumbers['lclE'], pointNumbers['lclA'], "#e0008d"),
]


