import ReactGA from "react-ga";

export const initGA = (trackingID) => {           
    ReactGA.initialize(trackingID, {
        debug: process.env.NODE_ENV === 'development'
    }); 
 }

 export const PageView = () => {  
    ReactGA.pageview(window.location.pathname +  
                     window.location.search); 
}

export const AnalyticsEvent = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

export const ObservePerformance = () => {

    const callback = list => {
      list.getEntries().forEach(entry => {
        ReactGA.timing({
          category: "Load Performace",
          variable: 'Server Latency',
          value: entry.responseStart - entry.requestStart 
        })
        ReactGA.timing({
          category: "Load Performace",
          variable: 'Download time',
          value: entry.responseEnd - entry.responseStart 
        })
        ReactGA.timing({
          category: "Load Performace",
          variable: 'Total app load time',
          value: entry.responseEnd - entry.requestStart
        })
    })
    }
    
    var observer = new window.PerformanceObserver(callback);
    observer.observe({entryTypes: ['navigation'] })
}

export const SetTrackedUserData = (uid) => {
    ReactGA.set({
        userId: uid
    })
}