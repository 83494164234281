const strings = [
    {
        notFound: 'Podane konto nie istnieje',
        noMatch: 'Niepoprawna kobinacja adresu email i hasła',
        alreadyExists: 'Na podany adres istnieje już zarejestrowane konto',
        userNotFound: 'Nie znaleziono konta powiązanego z tym adresem email.',
        userAlreadyVerified: 'Konto zostało już zweryfikowane.',
        accountNotVerified: 'Konto nie zostało jeszcze zweryfikowane.',
        userNotVerified: 'By dokonać tej operacji, konto musi być zweryfikowane.',
        noToken: 'Nie znaleziono tokena.',
        tokenExpired: 'Token stracił ważność. Wykonaj procedurę od nowa.',
        fail: 'Wysąpił błąd. Proszę spróbować później.'
    },
    {
        notFound: 'Could not find account',
        noMatch: 'The email and password combination does not match',
        alreadyExists: 'An account created using the given email already exists',
        alreadyExists: 'Na podany adres istnieje już zarejestrowane konto',
        userNotFound: 'No account bound to this email has been found.',
        userAlreadyVerified: 'The account has already been verified.',
        accountNotVerified: 'The account has not yet been verified.',
        userNotVerified: 'To complete this operation, the account must be verified.',
        noToken: 'Token not found.',
        tokenExpired: 'The token has expired. Please repeat the procedure from the start.',
        fail: 'An error occured. Please try again later.'
    }
]
export default strings