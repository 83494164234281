import {strings} from '../../../../assets/strings/Volume2D'
const pointNames = strings[localStorage.getItem('language') || 1].pointNames
const femaleReferenceCanvas = { width: 1044, height: 780, ratio: 1.34 };
const maleReferenceCanvas = { width: 1044, height: 638, ratio: 1.63 };
let correctPointPositions = {
  0: {
    id: "ref1a",
    x: -110.9,
    y: -226.88,
    category: "front_ref",
    left: false
  },
  1: {
    id: "ref1b",
    x: -43.37,
    y: -227.03,
    category: "front_ref",
    left: false
  },
  2: {
    id: "ref2a",
    x: 73.66,
    y: 0.41,
    category: "right_ref",
    left: false
  },
  3: {
    id: "ref2b",
    x: 104.72,
    y: 53.32,
    category: "right_ref",
    left: false
  },
  4: {
    id: "ref3a",
    x: -37.89,
    y: 75.03,
    category: "left_ref",
    left: true
  },
  5: {
    id: "ref3b",
    x: -22.42,
    y: 18.19,
    category: "left_ref",
    left: true
  },

  6: {
    id: "right_front_lateral",
    x: -304.48,
    y: 17.57,
    category: "front",
    left: false
  },
  7: {
    id: "right_front_medial",
    x: -9.26,
    y: 62.58,
    category: "front",
    left: false
  },
  8: {
    id: "right_front_nipple",
    x: -259.97,
    y: 130.82,
    category: "front",
    left: false
  },
  9: {
    id: "right_lateral_ctrl_a",
    x: -326.33,
    y: 66.27,
    category: "front",
    left: false
  },
  10: {
    id: "right_lateral_ctrl_b",
    x: -296.89,
    y: 116.3,
    category: "front",
    left: false
  },
  11: {
    id: "right_medial_ctrl_a",
    x: -37.36,
    y: 97.16,
    category: "front",
    left: false
  },
  12: {
    id: "right_medial_ctrl_b",
    x: -139.8,
    y: 158.52,
    category: "front",
    left: false
  },
  13: {
    id: "left_front_lateral",
    x: 310.39,
    y: -1.04,
    category: "front",
    left: true
  },
  14: {
    id: "left_front_medial",
    x: 20.43,
    y: 58.76,
    category: "front",
    left: true
  },
  15: {
    id: "left_front_nipple",
    x: 249.47,
    y: 138.68,
    category: "front",
    left: true
  },
  16: {
    id: "left_lateral_ctrl_a",
    x: 324.63,
    y: 105.36,
    category: "front",
    left: true
  },
  17: {
    id: "left_lateral_ctrl_b",
    x: 286.25,
    y: 123.42,
    category: "front",
    left: true
  },
  18: {
    id: "left_medial_ctrl_a",
    x: 43.52,
    y: 111.0,
    category: "front",
    left: true
  },
  19: {
    id: "left_medial_ctrl_b",
    x: 146.26,
    y: 147.69,
    category: "front",
    left: true
  },
  20: {
    id: "right_top",
    x: 221.96,
    y: -142.62,
    category: "lateral",
    left: false
  },
  21: {
    id: "right_bottom",
    x: 238.36,
    y: 125.43,
    category: "lateral",
    left: false
  },
  22: {
    id: "right_nipple",
    x: 346.0,
    y: 4.49,
    category: "lateral",
    left: false
  },
  23: {
    id: "right_top_ctrl_a",
    x: 237.49,
    y: -120.64,
    category: "lateral",
    left: false
  },
  24: {
    id: "right_top_ctrl_b",
    x: 332.51,
    y: -71.71,
    category: "lateral",
    left: false
  },
  25: {
    id: "right_bottom_ctrl_a",
    x: 291.08,
    y: 153.82,
    category: "lateral",
    left: false
  },
  26: {
    id: "right_bottom_ctrl_b",
    x: 357.7,
    y: 61.71,
    category: "lateral",
    left: false
  },
  27: {
    id: "left_top",
    x: -159.97,
    y: -138.68,
    category: "lateral",
    left: true
  },
  28: {
    id: "left_bottom",
    x: -185.69,
    y: 112.22,
    category: "lateral",
    left: true
  },
  29: {
    id: "left_nipple",
    x: -261.08,
    y: -44.78,
    category: "lateral",
    left: true
  },
  30: {
    id: "left_top_ctrl_a",
    x: -198.67,
    y: -105.66,
    category: "lateral",
    left: true
  },
  31: {
    id: "left_top_ctrl_b",
    x: -248.22,
    y: -69.92,
    category: "lateral",
    left: true
  },
  32: {
    id: "left_bottom_ctrl_a",
    x: -223.26,
    y: 140.23,
    category: "lateral",
    left: true
  },
  33: {
    id: "left_bottom_ctrl_b",
    x: -286.1,
    y: 38.46,
    category: "lateral",
    left: true
  }
};

let maleCorrectPointPositions = {
  0: {
    id: "ref1a",
    x: 15.25,
    y: -238.6,
    category: "front_ref",
    left: false
  },
  1: {
    id: "ref1b",
    x: 23.3,
    y: -213.0,
    category: "front_ref",
    left: false
  },
  2: {
    id: "ref2a",
    x: 80.69,
    y: 103.86,
    category: "right_ref",
    left: false
  },
  3: {
    id: "ref2b",
    x: 56.79,
    y: 126.99,
    category: "right_ref",
    left: false
  },
  4: {
    id: "ref3a",
    x: -76.60,
    y: -0.62,
    category: "left_ref",
    left: true
  },
  5: {
    id: "ref3b",
    x: -53.85,
    y: 27.13,
    category: "left_ref",
    left: true
  },

  6: {
    id: "right_front_lateral",
    x: -257.20,
    y: -21.57,
    category: "front",
    left: false
  },
  7: {
    id: "right_front_medial",
    x: -113.78,
    y: -17.72,
    category: "front",
    left: false
  },
  8: {
    id: "right_front_nipple",
    x: -203.86,
    y: -0.93,
    category: "front",
    left: false
  },
  9: {
    id: "right_lateral_ctrl_a",
    x: -241.78,
    y: -5.77,
    category: "front",
    left: false
  },
  10: {
    id: "right_lateral_ctrl_b",
    x: -229.44,
    y: -3.45,
    category: "front",
    left: false
  },
  11: {
    id: "right_medial_ctrl_a",
    x: -139.61,
    y: -3.45,
    category: "front",
    left: false
  },
  12: {
    id: "right_medial_ctrl_b",
    x: -171.22,
    y: -1.53,
    category: "front",
    left: false
  },
  13: {
    id: "left_front_lateral",
    x: 262.29,
    y: -22.29,
    category: "front",
    left: true
  },
  14: {
    id: "left_front_medial",
    x: 118.59,
    y: -42.62,
    category: "front",
    left: true
  },
  15: {
    id: "left_front_nipple",
    x: 211.12,
    y: -9.67,
    category: "front",
    left: true
  },
  16: {
    id: "left_lateral_ctrl_a",
    x: 249.67,
    y: -8.62,
    category: "front",
    left: true
  },
  17: {
    id: "left_lateral_ctrl_b",
    x: 231.45,
    y: -9.67,
    category: "front",
    left: true
  },
  18: {
    id: "left_medial_ctrl_a",
    x: 141.02,
    y: -24.74,
    category: "front",
    left: true
  },
  19: {
    id: "left_medial_ctrl_b",
    x: 177.82,
    y: -13.17,
    category: "front",
    left: true
  },
  20: {
    id: "right_top",
    x: 174.43,
    y: -60.93,
    category: "lateral",
    left: false
  },
  21: {
    id: "right_bottom",
    x: 171.55,
    y: 41.52,
    category: "lateral",
    left: false
  },
  22: {
    id: "right_nipple",
    x: 184.78,
    y: -10.94,
    category: "lateral",
    left: false
  },
  23: {
    id: "right_top_ctrl_a",
    x: 180.73,
    y: -43.97,
    category: "lateral",
    left: false
  },
  24: {
    id: "right_top_ctrl_b",
    x: 183.07,
    y: -31.74,
    category: "lateral",
    left: false
  },
  25: {
    id: "right_bottom_ctrl_a",
    x: 181.99,
    y: 19.51,
    category: "lateral",
    left: false
  },
  26: {
    id: "right_bottom_ctrl_b",
    x: 183.97,
    y: 6.92,
    category: "lateral",
    left: false
  },
  27: {
    id: "left_top",
    x: -162.53,
    y: -146.66,
    category: "lateral",
    left: true
  },
  28: {
    id: "left_bottom",
    x: -174.93,
    y: -52.42,
    category: "lateral",
    left: true
  },
  29: {
    id: "left_nipple",
    x: -179.72,
    y: -98.13,
    category: "lateral",
    left: true
  },
  30: {
    id: "left_top_ctrl_a",
    x: -170.80,
    y: -132.73,
    category: "lateral",
    left: true
  },
  31: {
    id: "left_top_ctrl_b",
    x: -174.50,
    y: -122.50,
    category: "lateral",
    left: true
  },
  32: {
    id: "left_bottom_ctrl_a",
    x: -182.06,
    y: -70.60,
    category: "lateral",
    left: true
  },
  33: {
    id: "left_bottom_ctrl_b",
    x: -181.46,
    y: -82.02,
    category: "lateral",
    left: true
  }
};

export const checkPointCoordinates = (canvasWidth, canvasHeight, points, isMale) => {
  const canvasRatio = canvasWidth / canvasHeight;

  const correct = Object.keys(correctPointPositions);
  const referenceCanvas = isMale ? maleReferenceCanvas : femaleReferenceCanvas;

  const correctPointPositionsGendered = isMale ? {...maleCorrectPointPositions} : {...correctPointPositions};

  for (let i = 0; i < points.length; i++) {
    if (points[i] !== null) {
      correct[i] = {
        name: pointNames[i],
        x: 0,
        y: 0,
        category: correctPointPositionsGendered[i].category,
        left: correctPointPositionsGendered[i].left
      };
      //FIX DIFFERENT RATIOS
      correct[i].x =
        (referenceCanvas.width / canvasWidth) *
          (canvasRatio / referenceCanvas.ratio) *
          points[i].x -
          correctPointPositionsGendered[i].x;

      correct[i].y =
        (referenceCanvas.height / canvasHeight) * points[i].y -
        correctPointPositionsGendered[i].y;
    }
  }
  return correct;
};
