export const strings = [
    {
        mainApp: {
            loadImageInfo: 'Wczytaj zdjęcie w panelu po lewej stronie'
        },
        sideDrawer: {
            backText: 'Powrót do menu',
            returnToMainText: 'Przejdź do strony głównej',
            appTitle: 'Szybka ocena',
            useChromeSuggestion: 'Dla maksymalnej jakości użytkowania, polecamy otwierać aplikację w przeglądarce Google Chrome.',
            validationHere: 'Prezentacja walidacji dostępna tutaj:',
            automaticProgression: 'Automatycznie uruchamiaj kolejny etap',
            showAnimatedInstructions: 'Pokazuj animowane instrukcje w trakcie dodawania punktów',
            sections: {
                photoLoad: 'Wczytanie fotografii',
                photoEdit: 'Obróbka zdjęć',
                referenceLine: 'Odcinek referencyjny',
                medialLine: 'Linia pośrodkowa',
                nac: 'Kompleks brodawka-otoczka',
                topBorder: 'Górny biegun',
                infraMammary: 'Fałd podpiersiowy',
                customPoints: 'Punkty i odcinki niestandardowe',
                report: 'Raport'

            },
            loadPatientPhoto: 'Wczytaj fotografię pacjenta:',
            loadSuppPhoto: '(Opcjonalnie) Wczytaj dodatkową fotografię:',
            skipPhotoEdit: "Pomiń obróbkę zdjęć",
            imageEdit: 'Edycja zdjęcia',
            suppImageEdit: 'Edycja dodatkowego zdjęcia',
            brightness: 'Jasność',
            contrast: 'Kontrast',
            saturation: 'Nasycenie',
            copyMainImageValues: 'Powiel wartości głównego zdjęcia',
            fixRotation: 'Popraw przekrzywione zdjęcie',
            fixSuppRotation: 'Dopasuj rotację dodatkowego zdjęcia',
            fixInstructionMain: 'Oznacz dwa charakterystyczne punkty na pierwszym zdjęciu',
            fixInstructionSupp: 'W tej samej kolejności oznacz te same punkty na drugim zdjęciu',
            edit: 'Edytuj',
            next: 'Dalej',
            point: 'Punkt',
            line: 'Odcinek',
            adjustRotation: 'Dopasuj',
            refLineHeading: 'Oznacz odcinek referencyjny',
            refLineInstruction: "Oznacz dwa końce odcinka na ciele pacjenta. Następnie wpisz ile wynosiła długość odcinka w centymetrach.",
            refLineHeadingSupp: 'Oznacz ten sam odcinek na drugim zdjęciu',
            medialLineHeading: 'Oznacz linię pośrodkową',
            medialLineInstructionMain: "Oznacz wcięcie mostka i pępek.",
            medialLineHeadingSupp: 'Oznacz linię pośrodkową na drugim zdjęciu',
            medialLineInstructionSupp: "W tej samej kolejności oznacz wcięcie mostka i pępek.",
            nacInstructions: 'Oznacz wskazane punkty według instrukcji dla sutka prawego i lewego.',
            rightSide: 'Strona prawa',
            leftSide: 'Strona lewa',
            topBorderInstructions: 'Wyznacz górny biegun każdej piersi, oznaczając wskazane punkty według instrukcji.',
            infraMammaryInstructions: 'Wyznacz fałd podpiersiowy każdej piersi, oznaczając wskazane punkty według instrukcji.',
            addCustomPoints: 'Własne punkty:',
            addCustomLines: 'Własne odcinki:',
            measurement: 'Pomiar',
            value: 'Wartość',
            selectAll: 'Zaznacz wszystkie',
            customMeasurements: 'Własne pomiary',
            customMeasurementDisclaimer: `Uwaga! Walidacja aplikacji była przeprowadzona na pomiarach widocznych w tabeli powyżej.
Własne pomiary będą poprawne matematycznie, lecz mogą nie odpowiadać pomiarom bezpośrednim. Prosimy traktować je z odpowiednią rezerwą.`,
            hidePointNames: 'Ukryj nazwy punktów',
            hideReferenceLines: 'Ukryj odcinki referencyjne',
            hideAllObjects: 'Ukryj wszystko',
            openReport: "Otwórz raport",
            addingPoint: 'Dodawanie punktów:',
            movingPoint: 'Przesuwanie punktów:',
            reportTableInfo: {
                patientData: 'Dane pacjenta',
                name: "Imię",
                surname: "Nazwisko",
                dateOfBirth: 'Data urodzenia',
                sex: "Płeć",
                female: 'Kobieta',
                male: 'Mężczyzna',
                assessorData: 'Dane badającego',
                assessorId: 'Identyfikator / Imię i nazwisko',
                comment: 'Komentarz',
                choose: 'Wybierz'
            },
            ratios: {
                title: "Proporcje",
                legend: "Legenda:",
                up: "UP - odległość od wierzchołka górnego bieguna do brodawki w linii pionowej",
                lp: "LP - odległość od wierzchołka fałdu podpiersiowego do brodawki w linii pionowej",
                lb: "LB - odległość od bocznej granicy piersi do brodawki w linii poziomej",
                mb: "MB - odległość od przyśrodkowej granicy piersi do brodawki w linii poziomej",
                left: "' - strona lewa",
                bestRatios: "Najbardziej pożądane proporcje:",
                ratio: 'Proporcja ',
                of: " wynosząca ",


            },
            customPointBox: {
                image1: 'Zdjęcie główne',
                image2: 'Zdjęcie dodatkowe'
            },
            customLineBox: {
                point1: 'Punkt 1:',
                point2: 'Punkt 2:',
                choose: 'Wybierz'
            },
            customMeasurement: {
                distance: 'Odległość',
                levelDifference: 'Różnica wysokości',
                distanceToMedial: 'Odległość od linii pośrodkowej',
                choose: 'Wybierz'
            },
            pointsPresentRequirement: "By wczytać odcinek, oba jego punkty muszą być dodane na zdjęciu.",
            loadAll: 'Wczytaj wszystkie',
            registration: {
                customPointsAndLines: 'Własne punkty i odcinki dostępne tylko dla zarejestrowanych użytkowników',
                haveAccount: 'Masz już konto?',
                signUp: 'Zarejestruj się',
                signIn: 'Zaloguj się',
                customMeasurements: 'Własne pomiary dostępne tylko dla zarejestrowanych użytkowników'
            }
        },
        firstRunModal: {
            welcome: 'Witaj w aplikacji BreastIdea - Szybka Ocena.',
            firstTime: 'Zauważyliśmy, że to Twój pierwszy raz tutaj, więc zachęcamy do zapoznania się z instrukcją obsługi.',
            showInstruction: 'Zobacz instrukcję',
            clicking: 'Klikając ',
            goBack: 'możesz zawsze do niej wrócić.',
            startApp: 'Przejdź do aplikacji',
            termsCb: [
                "Zapoznałam/em się z ",
                "Regulaminem",
                " aplikacji BreastIdea - Szybka Ocena i akceptuję jego treść.",
            ],
            termsNeeded: "Zaakceptowanie regulaminu jest konieczne do rozpoczęcia korzystania z aplikacji.",
            hideTerms: 'Ukryj Regulamin',
            terms: [
                "BreastIdea - Szybka Ocena jest aplikacją internetową dostarczaną przez serwis BreastIdea.",
                "Korzystając z niej użytkownik zobowiązany jest przestrzegać Regulaminu Serwisu oraz wymienionych w tym dokumencie. Brak zaakceptowania niniejszych warunków wiąże się z brakiem możliwości korzystania z serwisu.",
                "Aplikacja jest narzędziem pomocniczym. Użytkownik korzysta z niej na własne ryzyko, zapoznawszy się z procesem walidacji, z którego może uzyskać informacje dotyczące celności i precyzji uzyskiwanych wyników oraz ich korelacji z rzeczywistymi pomiarami.",
                "Twórcy nie odpowiadają za wyniki uzyskane w aplikacji i zrzekają się odpowiedzialności za wyniki uzyskane poprzez nieprawidłowe użytkowanie aplikacji lub błędy powstałe w wyniku problemów technicznych po stronie użytkownika lub stron trzecich, to jest dostawców usług hostingowych.",
                "Dane zapisane na koncie użytkownika są ładowane przy włączeniu aplikacji.",
                "Walidacja aplikacji przebiegała na podstawie pomiarów wbudowanych w aplikację. Własne pomiary mogą nie być zgodne z prawdą."
            ]

        },
        cookies: {
            textA: "Nasza strona używa plików cookie by usprawnić jakość użytkowania serwisu i pozwolić nam optymalizować nasze usługi.",
            textB: "Poprzez dalsze przeglądanie zgadzasz się na używanie plików cookie. Możesz zapoznać się z naszą ",
            pp: "Polityką Prywatności i Polityką plików Cookie ",
            here: "tutaj.",
            continue: "Rozumiem"
        },
        instructions: {
            title: 'Szybki pomiar',

            interface: {
                header: 'Interfejs',
                text: {
                    leftPanel: 'Panel po lewej stronie ekranu zawiera dziewięć sekcji.',
                    eachSection: 'Każda sekcja składa się z instrukcji oraz przycisków umożliwiających wykonanie wybranych pomiarów.',
                    rightPanel: 'Panel po prawej stronie zawiera zdjęcie/zdjęcia wczytane do aplikacji oraz menu.'
                }
            },
            usage: {
                header: 'Obsługa',
                text: {
                    inPointMode: 'W trybie punktu',
                    addPoint: `kliknięcie lewym przyciskiem myszy to dodawanie wybranego markera na zdjęcie. Kliknięcie i przytrzymanie na już istniejącym punkcie pozwala zmieniać jego położenie. `,
                    inMoveMode: 'W trybie kadrowania',
                    move: 'przytrzymanie lewego przycisku myszy i poruszanie kursorem umożliwia przemieszczanie zdjęcia.',
                    middleMove: 'W dowolnym trybie przytrzymanie kółka myszy (środkowego przycisku) i poruszanie kursorem również pozwala przemieszczać zdjęcia.',
                    inScaleMode: 'W trybie skalowania',
                    scale: 'przytrzymanie lewego przycisku i poruszanie kursorem pozwala zbliżać i oddalać obraz.',
                    middleScale: 'W dowolnym trybie poruszanie kółkiem myszy również pozwala zbliżać lub oddalać obraz.',
                    rmb: 'Kliknięcie prawym przyciskiem myszy na zdjęcie otwiera menu kontekstowe.'
                }
            },
            howToUse: {
                header: 'Jak używać aplikacji',
                text: {
                    load: 'Wczytaj zdjęcie/zdjęcia osoby badanej',
                    edit: 'Opcjonalnie popraw kolory zdjęcia/zdjęć i popraw pion zdjęcia',
                    rotate: 'Pion drugiego zdjęcia może być dopasowany do zdjęcia pierwszego, oznaczając dwa charakterystyczne punkty na każdym z nich',
                    refLine: 'Oznacz odcinek referencyjny na ciele osoby badanej, a następnie podaj jego długość rzeczywistą (w centymetrach)',
                    medial: 'Oznacz linię pośrodkową osoby badanej, wyznaczając ją w oparciu o wcięcie mostka i pępek',
                    markers: 'Postępuj według instrukcji by oznaczyć wszystkie markery lub wybierz tylko interesujące cię pomiary',
                    custom: 'Możesz dodać własne punkty i odcinki w odpowiedniej sekcji',
                    table: 'W ostatniej sekcji wyświetlana jest aktualizowana w czasie rzeczywistym tabela pomiarów. Możesz wybrać które pomiary mają być zawarte w raporcie',
                    cMeasurements: 'Na końcu tabeli możesz dodać własne pomiary, wybierając rodzaj pomiaru (odległość, różnica wysokości, odległość od linii pośrodkowej) i punkty',
                    form: 'Poniżej tabeli znajduje się formularz, w który możesz wpisać dane oraz komentarz do zdjęć',
                    fixPhoto: 'Ustaw (pozycja, przybliżenie) zdjęcia tak, jak chcesz by widniały w raporcie',
                    clickBtn: 'Kliknij przycisk',
                    report: 'Raport',
                    toOpen: 'by otworzyć gotowy do druku raport.',
                    reportFix: 'Przed wydrukiem dostosuj zawarte w raporcie zdjęcie/zdjęcia',
                    press: 'Naciśnij przycisk',
                    print: 'Drukuj',
                    toPrint: 'by wydrukować rapot w formie fizycznej lub pliku PDF (sposób wyboru zależny od używanej przeglądarki)',
                    click: 'Kliknij',
                    return: 'Wróć',
                    toReturn1: 'by powrócić do aplikacji. Następnie w sekcji ',
                    toReturn2: 'Wczytaj zdjęcia',
                    toReturn3: ' naciśnij ',
                    toReturn4: 'Reset',
                    toReturn5: ' by rozpocząć nowe pomiary.',
                    customSave: 'Własne punkty zostaną zachowane do czasu odświeżenia strony.'
                }
            },
            interfaceDiscussion: {
                header: 'Omówienie interfejsu',
            },


            sideDrawer: {
                header: 'Panel lewostronny',
                subtitles: {
                    autoRun: 'Automatycznie uruchamiaj kolejny etap',
                    showAnim: 'Pokazuj animowane instrukcje w trakcie dodawania punktów',
                    load: 'Wczytanie fotografii',
                    edit: 'Obróbka zdjęć',
                    ref: 'Odcinek referencyjny',
                    medial: 'Linia pośrodkowa',
                    nac: 'Kompleks brodawka-otoczka',
                    uc: 'Górny biegun',
                    lc: 'Fałd podpiersiowy'
                },
                text: {
                    autoRun: 'po oznaczeniu wymaganych markerów, aplikacja automatycznie uruchamia kolejny etap pomiaru',
                    showAnim: 'gdy dodajesz punkty, wyświetlane są podstawowe, wizualne instrukcje obsługi markerów',
                    load: 'kliknięcie otwiera przeglądarkę plików. Wybierz zdjęcie osoby badanej. Dopuszczalne formaty to: .jpg, .png, .bmp, .gif.',
                    edit: `by lepiej rozpoznawać punkty orientacyjne na ciele osoby badanej, możesz dostosować jasność, kontrast i nasycenie zdjęcia.
                    Możesz też poprawić przekrzywione zdjęcie przeciągając suwakiem lub wpisując kąt obrotu by ustawić osobę badaną na zdjęciu w linii pionowej`,
                    editRotation: `W przypadku pracy w trybie dwóch zdjęć, możesz spasować ich rotację. Wybierz charakterystyczne punkty na pierwszem zdjęciu (np. sutki) i oznacz je również na drugim.
                    Następnie kliknij przycisk `,
                    editRotationFix: 'Dopasuj',
                    ref: `na ciele pacjenta musi znajdować się znanej długości odcinek. 
                    Oznaczenie go i wpisanie jego rzeczywistej długości umożliwia przeliczenie pikseli zdjęcia na jednostki SI.`,
                    medial: `oznaczenie odcinka między wcięciem mostka i pępkiem umożliwia wyznaczenie linii pośrodkowej ciała osoby badanej. 
                    Linia ta wymagana jest do niektórych pomiarów oraz jest podstawą dopasowania w trybie dwóch zdjęć.`,
                    nac: `postępuj według dynamicznych instrukcji pod zdjęciem by oznaczyć zestaw pięciu punktów na sutku. 
                    Zwróć uwagę na symetrię puntków D i E względem linii pośrodkowej`,
                    uc: `wyznacz punkty odcięcia krzywej, a następnie jej wierzchołek. Możesz w ten sposób precyzyjnie określić bieg górnego bieguna piersi. 
                    Aplikacja automatycznie wybierze drugie zdjęcie, jeśli zostało wczytane.`,
                    lc: `wyznacz punkty odcięcia krzywej, a następnie jej wierzchołek. Możesz w ten sposób precyzyjnie określić bieg fałdu podpiersiowego. 
                    Aplikacja automatycznie wybierze drugie zdjęcie, jeśli zostało wczytane.`

                }
            },
            photoArea: {
                header: 'Panel prawostronny',
                text: {
                    point: 'tryb punktu. W tym trybie możesz dodawać punkty i je przemieszczać.',
                    move: 'tryb kadrowania. W tym trybie możesz przemieszczać zdjęcie, by precyzyjniej dodawać markery.',
                    scale: 'tryb skalowania. W tym trybie możesz powiększać i pomniejszać obraz',
                    switch: '(tylko w trybie dwóch zdjęć) zmień aktualnie wyświetlane zdjęcie. Niedostępne w trakcie dodawania punktów.',
                    manual: 'wyświetl instrukcję obsługi aplikacji.'
                }
            },
            contextMenu: {
                header: 'Menu kontekstowe',
                subtitles: {
                    reset: 'Resetuj obraz',
                    coords: 'Pokaż koordynaty punktu',
                    fps: 'FPS: '
                },
                text: {
                    reset: 'wyzeruj przemieszczenie i przybliżenie obrazu.',
                    coords: 'nazwa i pozycja aktualnie wybranego punktu (pomarańczowa obwódka) zostanie pokazana w panelu w prawym górnym rogu zdjęcia.',
                    fps: `dostosuj jak często odświeżany jest obraz. 
Małe wartości to mniej płynne poruszanie punktów i zdjęcia, ale mniejsze obciążenie komputera. Minimum 5, maksimum 60 klatek na sekundę.`
                }
            },

        },
        photoArea: {
            nacInstructions: {
                nipple: 'Sutek: ',
                point: 'Punkt: ',
                right: 'Prawy',
                left: 'Lewy'
            },
            contextMenu: {

                reset: 'Resetuj pozycję obrazu',
                show: 'Pokaż',
                hide: 'Ukryj',
                pointCoordinates: ' koordynaty punktu',
                fps: 'FPS: '
            },
        },


        footer: {
            localComputation: `Oprogramowanie nie zapisuje i nie przechowuje danych wrażliwych. 
            Obliczenia przeprowadzane są w przegladarce, uzywajac plików lokalnych uzytkownika.`,
            ToS: `Regulamin Serwisu`,
            appToS: `Regulamin modułu Objętość 2D`,
            PP: `Polityka Prywatności`,
            contact: `Kontakt`
            
        },
        printPage: {
            title: 'Szybki pomiar',
            patientData: 'Dane pacjenta',
            name: 'Imię',
            surname: 'Nazwisko',
            sex: 'Płeć',
            female: 'Kobieta',
            male: 'Mężczyzna',
            dateOfBirth: 'Data urodzenia',
            printDate: 'Data wydruku',
            observer: 'Badający',
            measurement: 'Pomiar',
            value: 'Wartość',
            nac: 'Kompleks brodawka-otoczka',
            uc: 'Górny biegun',
            lc: 'Fałd podpiersiowy',
            ratios: "Proporcje",
            custom: 'Własne pomiary',
            adjustImagePos: 'Dostosuj pozycję zdjęcia',
            comment: 'Komentarz',
            return: 'Wróć',
            print: 'Drukuj',
            up: "UP - odległość od wierzchołka górnego bieguna do brodawki w linii pionowej",
            lp: "LP - odległość od wierzchołka fałdu podpiersiowego do brodawki w linii pionowej",
            lb: "LB - odległość od bocznej granicy piersi do brodawki w linii poziomej",
            mb: "MB - odległość od przyśrodkowej granicy piersi do brodawki w linii poziomej",
        },
        measurements: {
            aap: "Różnica wysokości A - A'",
            a2medial: "Odległość A - linia pośrodkowa",
            ap2medial: "Odległość A' - linia pośrodkowa",
            a2inc: "Odległość A - SN",
            ap2inc: "Odległość A' - SN",
            b2c: "Odległość B - C",
            bp2cp: "Odległość B' - C'",
            d2e: "Odległość D - E",
            dp2ep: "Odległość D' - E'",
            ucrucl: "Różnica wysokości wierzchołek prawy - wierzchołek lewy",
            ucr2a: "Odległość wierzchołek prawy - A",
            ucl2ap: "Odległość wierzchołek lewy - A'",
            ucr2aucl2ap: "Różnica",
            lcrlcl: "Różnica wysokości wierzchołek prawy - wierzchołek lewy",
            lcr2medial: "Odległość wierzchołek prawy - linia pośrodkowa",
            lcl2medial: "Odległość wierzchołek lewy - linia pośrodkowa",
            lcr2mediallcl2medial: "Różnica",
            lcr2a: "Odległość wierzchołek prawy - A",
            lcl2ap: "Odległość wierzchołek lewy - A'",
            lcr2alcl2ap: "Różnica",
            uplp: "UP : LP",
            upplpp: "UP' : LP'",
            lbmb: "LB : MB",
            lbpmbp: "LB' : MB'"

        },
        pointNames: {
            0: '1',
            1: '2',
            2: "1'",
            3: "2'",
            4: 'R1',
            5: 'R2',
            6: "R1'",
            7: "R2'",
            8: 'SN',
            9: 'UMB',
            10: "SN'",
            11: "UMB'",
            12: 'A',
            13: 'B',
            14: 'C',
            15: 'D',
            16: 'E',
            17: "A'",
            18: "B'",
            19: "C'",
            20: "D'",
            21: "E'",
            22: "∩ P",
            23: "∩ K",
            24: "∩ W",
            25: "∩ P'",
            26: "∩ K'",
            27: "∩ W'",
            28: "∪ P",
            29: "∪ K",
            30: "∪ W",
            31: "∪ P'",
            32: "∪ K'",
            33: "∪ W'",
        },
    },
    {
        mainApp: {
            loadImageInfo: 'Load a photograph in the left panel'
        },
        sideDrawer: {
            backText: 'Back to menu',
            returnToMainText: 'Access main site',
            appTitle: 'Quick assessment',
            useChromeSuggestion: 'For best user experience, we recommend running the application in Google Chrome.',
            validationHere: 'Presentation of validation available here:',
            automaticProgression: 'Automatic progression',
            showAnimatedInstructions: 'Show animated instructions while adding points',
            sections: {
                photoLoad: 'Load photographs',
                photoEdit: 'Edit photographs',
                referenceLine: 'Reference line',
                medialLine: 'Medial line',
                nac: 'Nipple-areolar complex',
                topBorder: 'Upper pole',
                infraMammary: 'Inframammary fold',
                customPoints: 'Custom points and lines',
                report: 'Report'

            },
            loadPatientPhoto: 'Load patient photograph:',
            loadSuppPhoto: '(Optionally) Load supplementary photograph:',
            skipPhotoEdit: "Skip photograph editing",
            imageEdit: 'Edit photograph',
            suppImageEdit: 'Edit supplementary photograph',
            brightness: 'Brightness',
            contrast: 'Contrast',
            saturation: 'Saturation',
            copyMainImageValues: 'Copy main photograph values',
            fixRotation: 'Correct rotated photograph',
            fixSuppRotation: 'Match supplementary image rotation',
            fixInstructionMain: 'Mark two characteristic points on the main photograph',
            fixInstructionSupp: 'In the same order, mark the points on the supplementary photograph',
            edit: 'Edit',
            next: 'Next',
            point: 'Point',
            line: 'Line segment',
            adjustRotation: 'Match',
            refLineHeading: 'Mark the reference line segment',
            refLineInstruction: "Place a point on each end of the line segment. Next provide the length of the segment in centimetres.",
            refLineHeadingSupp: 'Mark the reference line segment on the supplementary photograph',
            medialLineHeading: 'Mark the medial line',
            medialLineInstructionMain: "Place points on the sternal notch and umbilicus.",
            medialLineHeadingSupp: 'Mark the medial line on the supplementary photograph',
            medialLineInstructionSupp: "In the same order, place points on the sternal notch and umbilicus.",
            nacInstructions: 'For each nipple, place points in areas indicated by the corresponding visual guides.',
            rightSide: 'Right side',
            leftSide: 'Left side',
            topBorderInstructions: 'Outline the upper pole of each breast, placing points according to corresponding visual guides.',
            infraMammaryInstructions: 'Outline the inframammary fold of each breast, placing points according to corresponding visual guides.',
            addCustomPoints: 'Custom points:',
            addCustomLines: 'Custom line segments:',
            measurement: 'Measurement',
            value: 'Value',
            selectAll: 'Select all',
            customMeasurements: 'Custom measurements',
            customMeasurementDisclaimer: `Warning! The application was validated on measurements listed in the table above.
            Custom measurements will be mathematically correct, but may not correspond to physical measurements. Please treat them with limited trust.`,
            hidePointNames: 'Hide point names',
            hideReferenceLines: 'Hide reference lines',
            hideAllObjects: 'Hide all',
            openReport: "Open report",
            addingPoint: 'Adding points:',
            movingPoint: 'Moving points:',
            ratios: {
                title: "Ratios",
                legend: "Legend:",
                up: "UP - vertical distance from the upper pole apex to the nipple",
                lp: "LP - vertical distance from the inframammary fold apex to the nipple",
                lb: "LB - horizontal distance from the breast's lateral border to the nipple",
                mb: "MB - horizontal distance from the breast's medial border to the nipple",
                left: "' - left side",
                bestRatios: "Most appealing ratios:",
                ratio: '',
                of: " ratio of ",


            },
            reportTableInfo: {
                patientData: 'Patient data',
                name: "Fist name",
                surname: "Last name",
                dateOfBirth: 'Date of birth',
                sex: "Sex",
                female: 'Female',
                male: 'Male',
                assessorData: 'Doctor data',
                assessorId: 'ID / First name & last name',
                comment: 'Commentary',
                choose: 'Choose'
            },
            customPointBox: {
                image1: 'Main photograph',
                image2: 'Supplementary photograph'
            },
            customLineBox: {
                point1: 'Point 1:',
                point2: 'Point 2:',
                choose: 'Choose'
            },
            customMeasurement: {
                distance: 'Distance',
                levelDifference: 'Level difference',
                distanceToMedial: 'Distance to medial line',
                choose: 'Choose'
            },
            loadAll: 'Load all',
            pointsPresentRequirement: "To load a line segment, both its points need to be present on a photograph.",
            registration: {
                customPointsAndLines: 'Custom points and line segments available only for registered users',
                haveAccount: 'Already got an account?',
                signUp: 'Sign Up',
                signIn: 'Sign In',
                customMeasurements: 'Custom measurements available only for registered users'
            }
        },
        firstRunModal: {
            welcome: 'Welcome to BreastIdea - Quick Assessment.',
            firstTime: "We noticed it's your first time here, so we encourage you to take a look at the manual",
            showInstruction: 'Show manual',
            clicking: 'Clicking on ',
            goBack: 'will bring back the manual.',
            startApp: 'Start the application',
            termsCb: [
                "I have read and agree to the ",
                "Terms of Service",
                " of BreastIdea - Quick Assessment Application.",
            ],
            termsNeeded: "Accepting the Terms of Service is necessary to start using the application.",
            hideTerms: 'Hide Terms of Service',
            terms: [
                "BreastIdea - Quick Assessment is an web application provided by the BreastIdea Website.",
                "By using the application, the user is obliged to comply with the General Terms of Service of the Website and those listed in this document. If you do not accept these terms, you will not be able to use the application.",
                "The application is an auxiliary tool. The user uses it at their own risk, becoming acquainted with the validation process, from which they can get information on the accuracy and precision of the obtained results and their correlation with the actual measurements.",
                "The developers are not responsible for the results obtained in the application and relinquish responsibility for the results obtained through the incorrect use of the application or errors arising as a result of technical problems on the part of the user or third parties, that is, the hosting service providers.",
                "The data saved in the user's account is loaded when the application is started.",
                "Application validation was based on measurements built into the application. Custom measurements may not be valid."
            ]

        },
        cookies: {
            textA: "Our website uses cookies to make your experience smoother and allow us to optimise our service.",
            textB: "By continuing to browse this website you accept the use of cookies. Read more about our ",
            pp: "Privacy Policy and Cookie Policy ",
            here: "here.",
            continue: "OK"
        },
        instructions: {
            title: 'Quick assessment',

            interface: {
                header: 'Interface',
                text: {
                    leftPanel: 'The panel on the left side of the screen contains nine sections.',
                    eachSection: 'Each section consists of instructions and buttons, guiding the user through breast measurements.',
                    rightPanel: 'The panel on the right side of the screen contains the photograph/photographs supplied by the user and a menu.'
                }
            },
            usage: {
                header: 'Usage',
                text: {
                    inPointMode: 'In point mode',
                    addPoint: `left clicking on the photo adds a marker. Left clicking and dragging on an existing point allows to move it around.`,
                    inMoveMode: 'In framing mode',
                    move: 'left clicking and dragging allows to change position of the photograph.',
                    middleMove: 'While the photograph is in context, clicking and dragging with the mousewheel (middle mouse button) allows to move the photograph regardless of mode.',
                    inScaleMode: 'In zoom mode',
                    scale: 'left clicking and dragging allows to change image zoom level',
                    middleScale: 'While the photograph is in context, scrolling with the mousewheel zooms the image in and out regardless of mode.',
                    rmb: 'Right clicking the photograph opens up a menu with additional options.'
                }
            },
            howToUse: {
                header: 'How to use the application',
                text: {
                    load: "Load the patient's photograph/photographs",
                    edit: "Optionally correct each photograph's brightness, contrast, hue and rotation",
                    rotate: "Photograph rotations can be matched by marking the same two characteristic landmarks on both of them",
                    refLine: "Mark the reference line segment on the patient's body and supply its real length in centimetres",
                    medial: "Mark the patient's medial line, placing points on the sternal notch and umbilicus",
                    markers: "Follow instructions to place all markers or choose only the ones necessary for your measurements of interest",
                    custom: "You can add custom points and line segments in the appropriate section",
                    table: "A dynamically updated table of measurements is found in the last section. You can choose which measurements are to be included in the report",
                    cMeasurements: "You can add custom measurements below the table, choosing measurement type (distance, height difference, distance to medial line) and appropriate points",
                    form: "You can provide patient and doctor data, as well as a field to write a commentary",
                    fixPhoto: "Adjust the photographs (position, zoom) the way you want them to be displayed in the report",
                    clickBtn: "Click",
                    report: "Report",
                    toOpen: "to open a print-ready, dynamic report.",
                    reportFix: "Before print, you can adjust the point of interest of included photographs",
                    press: "Click",
                    print: "Print",
                    toPrint: "to print the report as a physical copy or PDF file (browser specific)",
                    click: "Click",
                    return: "Back",
                    toReturn1: "to return to the application. Next in the section ",
                    toReturn2: "Load photographs",
                    toReturn3: " click ",
                    toReturn4: "Reset",
                    toReturn5: " to start anew.",
                    customSave: "Custom points will be saved until page refresh"
                }
            },
            interfaceDiscussion: {
                header: 'Interface discussion',
            },


            sideDrawer: {
                header: 'Left panel',
                subtitles: {
                    autoRun: 'Automatic progression',
                    showAnim: 'Show animated instructions while adding points',
                    load: 'Load photographs',
                    edit: 'Edit photographs',
                    ref: 'Reference line',
                    medial: 'Medial line',
                    nac: 'Nipple-areolar complex',
                    uc: 'Upper pole',
                    lc: 'Inframammary fold'
                },
                text: {
                    autoRun: 'when all markers are placed, the next section is automatically started',
                    showAnim: 'a simple visual guideline is shown when adding new points',
                    load: 'a file browser is opened on click. Choose the photograph of the patient. Allowed file formats: .jpg, .png, .bmp, .gif.',
                    edit: `to better recognize anatomical landmarks on the patient's body, the image's brightness, contrast and saturation can be adjusted.
                    You can also fix a skewed photograph by dragging the rotation slider or supplying a desired rotation angle to make the patient truly vertical`,
                    editRotation: `When working in two photo mode, you can match their rotation. Pick to characteristic landmarks on the main photograph (e.g. the nipples) and mark them on both photographs.
                    Then click `,
                    editRotationFix: 'Match',
                    ref: `a line segment of known length needs to be present on the patient's body.
                    Marking it and providing its real length makes it possible to recalculate pixels in the photo to SI units.`,
                    medial: `creating a line segment between the sternal notch and the umbilicus demarcates the medial line of the patient's body.
                    This line is necessary for some measurements and is used to adjust photographs.`,
                    nac: `follow the dynamic instructions below the photograph to place five points on the nipple.
                    Make notice of symmetry of points D and E along the medial line.`,
                    uc: `place curve cut-off points and then its apex. This way you can precisely outline the breast's upper pole.
                    The supplementary photograph will automatically be chosen if provided.`,
                    lc: `place curve cut-off points and then its apex. This way you can precisely outline the breast's inframammary fold.
                    The supplementary photograph will automatically be chosen if provided.`,

                }
            },
            photoArea: {
                header: 'Right pannel',
                text: {
                    point: 'point mode. When this mode is active, you can add and move points.',
                    move: 'framing mode. When this mode is active, you can move the image to place markers more precisely.',
                    scale: 'zoom mode. When this mode is active, you can zoom into and out of the image',
                    switch: '(only in two photo mode) change the currently displayed photograph. Unavailable when adding points.',
                    manual: 'show the instruction manual.'
                }
            },
            contextMenu: {
                header: 'Context menu',
                subtitles: {
                    reset: 'Reset image',
                    coords: 'Show point coordinates',
                    fps: 'FPS: '
                },
                text: {
                    reset: 'revert the image translation and scale back to initial values.',
                    coords: 'name and position of the currently selected point (orange stroke) will be shown in the top right hand corner of the image.',
                    fps: `adjust how often the image refreshes. 
Low values mean less fluid movement of points and images, but offer less performace load. Minimum 5, maximum 60 frames per second.`
                }
            },

        },
        photoArea: {
            nacInstructions: {
                nipple: 'Nipple: ',
                point: 'Point: ',
                right: 'Right',
                left: 'Left'
            },
            contextMenu: {

                reset: 'Reset image position',
                show: 'Show',
                hide: 'Hide',
                pointCoordinates: ' point coordinates',
                fps: 'FPS: '
            },
        },

        footer: {
            localComputation: `The application does not store or save sensitive data.
            All calculations are conducted in the browser, using the user's local files.`,
            ToS: `General Terms of Service`,
            appToS: `Quick Assessment Terms of Service`,
            PP: `Privacy Policy`,
            contact: `Contact`
        },
        printPage: {
            title: 'Quick assessment',
            patientData: 'Patient data',
            name: 'First name',
            surname: 'Last name',
            sex: 'Sex',
            female: 'Female',
            male: 'Male',
            dateOfBirth: 'Date of birth',
            printDate: 'Print date',
            observer: 'Doctor',
            measurement: 'Measurement',
            value: 'Value',
            nac: 'Nipple-areolar complex',
            uc: 'Upper pole',
            lc: 'Inframammary fold',
            ratios: 'Ratios',
            custom: 'Custom measurements',
            adjustImagePos: 'Adjust image position',
            comment: 'Commentary',
            return: 'Back',
            print: 'Print',
            up: "UP - vertical distance from the upper pole apex to the nipple",
            lp: "LP - vertical distance from the inframammary fold apex to the nipple",
            lb: "LB - horizontal distance from the breast's lateral border to the nipple",
            mb: "MB - horizontal distance from the breast's medial border to the nipple",
        },
        measurements: {
            aap: "Level difference A - A'",
            a2medial: "Distance A - medial line",
            ap2medial: "Distance A' - medial line",
            a2inc: "Distance A - SN",
            ap2inc: "Distance A' - SN",
            b2c: "Distance B - C",
            bp2cp: "Distance B' - C'",
            d2e: "Distance D - E",
            dp2ep: "Distance D' - E'",
            ucrucl: "Apex levels difference",
            ucr2a: "Distance ∩A - A",
            ucl2ap: "Distance ∩A' - A'",
            ucr2aucl2ap: "Difference",
            lcrlcl: "Apex levels difference",
            lcr2medial: "Distance ∪A - medial line",
            lcl2medial: "Distance ∪A' - medial line",
            lcr2mediallcl2medial: "Difference",
            lcr2a: "Distance ∪A - A",
            lcl2ap: "Distance ∪A' - A'",
            lcr2alcl2ap: "Difference",
            uplp: "UP : LP",
            upplpp: "UP' : LP'",
            lbmb: "LB : MB",
            lbpmbp: "LB' : MB'"
        },
        pointNames: {
            0: '1',
            1: '2',
            2: "1'",
            3: "2'",
            4: 'R1',
            5: 'R2',
            6: "R1'",
            7: "R2'",
            8: 'SN',
            9: 'UMB',
            10: "SN'",
            11: "UMB'",
            12: 'A',
            13: 'B',
            14: 'C',
            15: 'D',
            16: 'E',
            17: "A'",
            18: "B'",
            19: "C'",
            20: "D'",
            21: "E'",
            22: "∩ S",
            23: "∩ E",
            24: "∩ A",
            25: "∩ S'",
            26: "∩ E'",
            27: "∩ A'",
            28: "∪ S",
            29: "∪ E",
            30: "∪ A",
            31: "∪ S'",
            32: "∪ E'",
            33: "∪ A'",
        }
    },

]