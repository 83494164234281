import * as actionTypes from './actionTypes'
import axios from 'axios'


export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}



export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  }
}


export const logout = () => {
  return dispatch => {
      return new Promise( async (resolve, reject) => {

          try{
              await axios.post('/users/logout')

              
              
          } catch (e) {
              console.log(e)
          }
          dispatch ( {type: actionTypes.AUTH_LOGOUT})
          resolve()
      })
  }
}


export const signIn = (credentials, token) => {
  return (dispatch, getState) => {
      return new Promise( async (resolve, reject) => {
        dispatch(authStart());
          try {
              const res = await axios.post('/users/login', {credentials, token})
              const profile = res.data
              const uid = profile._id
              delete profile._id
              dispatch( {type: "AUTH_SUCCESS", profile, uid})

              resolve()
          } catch (e) {
              
              dispatch( authFail(e.response.data.message))

              reject(e)
          }
      })
  }

}

export const signUp = (credentials, token) => {
  return (dispatch) => {
      return new Promise( async (resolve, reject) => {
        dispatch(authStart());
          try {
              const res = await axios.post('/users', {credentials, token})

              dispatch( {type: "AUTH_SUCCESS"})

              resolve()
          } catch (e) {
              
              dispatch( authFail(e.response.data.message))

              reject(e)
          }
      })
  }
}

// export const auth = (payload, isSignup, rememberMe) => {
//   return dispatch => {
//     dispatch(authStart());
//     const authData = { ...payload }
//     let url = '/users'
//     if (!isSignup) {
//       url = '/users/login'
//     }

//     axios.post(url, authData)
//       .then(res => {
//         if (!isSignup && rememberMe) {
//           //localStorage.setItem('token', res.headers['x-auth'])
//           dispatch(authSuccess(res.data, res.data._id))
//         }else{
//           dispatch(authSuccess())
//         }

//       }).catch(err => {
//         dispatch(authFail(err.message))
//         //Czemu to tu było?
//         // setInterval(() => {
//         //   dispatch(authFail())
//         // }, 5000);
//       })
//   }
// }

export const authCheckState =  (params) => {
  return dispatch => {

      return new Promise( async (resolve, reject) => {
          try {
             // console.log('authCheck')
              
              const res = await axios('/users/me')
              const profile = res.data
              const uid = profile._id
              delete profile._id
              dispatch( {type: "AUTH_SUCCESS", profile, uid}) //DISPATCH IS SYNCHRONOUS!!!

              resolve(uid)
              
          } catch (e) {
              dispatch( authFail(e.response.data.message))
              logout();
              resolve(null)
              
          }
          
          
      })
  }
}


export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  }
}

export const setLanguage = (language) => {
  return {
    type: actionTypes.SET_LANGUAGE,
    language: language
  }
}