import { apply, set } from 'redecorate'
import * as actionTypes from '../actions/actionTypes'
import { pointNumbers, points, pointNames, lines, addLine } from './../../components/QuickAssessment/PhotoArea/CanvasObjects';

import tempImage from '../../assets/images/quick-assessment.jpg'

import { calculateValues } from '../../components/QuickAssessment/SideDrawer/Calculations';
import { strings } from '../../assets/strings/QuickAssessment'


//AUTOSTART SECTIONS!!!

const initialState = {
    autostartSections: false,
    mainFileName: '...',
    suppFileName: '...',
    mainImage: null,
    suppImage: null,
    mainImageRatio: 1.3333,
    suppImageRatio: 1.3333,
    mainImageRotation: 0,
    suppImageRotation: 0,
    currentPoint: 0,
    previousPointId: '',
    currentPointId: '',
    currentImage: 0,
    canDraw: false,
    automaticProgression: false,
    showAnimatedInstructions: true,
    currentSubsection: 'rotationAdjust',
    subsectionComplete: {
        loadImages: {
            main: false,
            supp: false
        },
        rotationAdjust: {
            main: {
                adjustRotation1a: false,
                adjustRotation1b: false,
            },
            supp: {
                adjustRotation2a: false,
                adjustRotation2b: false,
            }
        },
        referenceLine: {
            main: {
                ref1a: false,
                ref1b: false,
            },
            supp: {
                ref2a: false,
                ref2b: false,
            }
        },
        medialLine: {
            main: {
                sn1: false,
                umb1: false,
            },
            supp: {
                sn2: false,
                umb2: false,
            }
        },
        nippleAreolarComplex: {
            main: {
                a: false,
                b: false,
                c: false,
                d: false,
                e: false,
                ap: false,
                bp: false,
                cp: false,
                dp: false,
                ep: false,
            }
        },
        upperCurve: {
            main: {
                ucrS: false,
                ucrE: false,
                ucrA: false,
                uclS: false,
                uclE: false,
                uclA: false,
            },
            supp: {
                ucrS: false,
                ucrE: false,
                ucrA: false,
                uclS: false,
                uclE: false,
                uclA: false,
            }
        },
        lowerCurve: {
            main: {
                lcrS: false,
                lcrE: false,
                lcrA: false,
                lclS: false,
                lclE: false,
                lclA: false
            },
            supp: {
                lcrS: false,
                lcrE: false,
                lcrA: false,
                lclS: false,
                lclE: false,
                lclA: false
            }

        },
        custom: {
            main: {

            },
        }
    },
    mainImageRetouch: {
        brightness: 100,
        contrast: 100,
        saturation: 100
    },
    suppImageRetouch: {
        brightness: 100,
        contrast: 100,
        saturation: 100
    },
    referenceValues: {
        main: 0,
        supp: 0
    },
    customPoints: [],
    customLines: [],
    customMeasurements: [],
    showPointNames: true,
    showReferenceLines: true,
    showAllObjects: true,
    calculatedValues: {
        aap: { value: '-', name: '', left: false, higher: '', },
        a2medial: { value: '-', name: '', left: false, },
        ap2medial: { value: '-', name: "", left: true, },
        a2inc: { value: '-', name: "", left: false, },
        ap2inc: { value: '-', name: "", left: true, },
        b2c: { value: '-', name: "", left: false, },
        bp2cp: { value: '-', name: "", left: true, },
        d2e: { value: '-', name: "", left: false, },
        dp2ep: { value: '-', name: "", left: true, },
        ucrucl: { value: '-', name: "", left: false, higher: '', },
        ucr2a: { value: '-', name: "", left: false, },
        ucl2ap: { value: '-', name: "", left: true, },
        ucr2aucl2ap: { value: '-', name: "", left: false, secondary: true },
        lcrlcl: { value: '-', name: "", left: false, higher: '', },
        lcr2medial: { value: '-', name: "", left: false, },
        lcl2medial: { value: '-', name: "", left: true, },
        lcr2mediallcl2medial: { value: '-', name: "", left: false, secondary: true },
        lcr2a: { value: '-', name: "", left: false, },
        lcl2ap: { value: '-', name: "", left: true, },
        lcr2alcl2ap: { value: '-', name: "", left: false, secondary: true },
        uplp: { value: '-', name: "", left: false,  },
        upplpp: { value: '-', name: "", left: true, },
        lbmb: { value: '-', name: "", left: false,  },
        lbpmbp: { value: '-', name: "", left: true, },
    },
    exportedMeasurements: {
        aap: true,
        a2medial: true,
        ap2medial: true,
        a2inc: true,
        ap2inc: true,
        b2c: false,
        bp2cp: false,
        d2e: false,
        dp2ep: false,
        ucrucl: true,
        ucr2a: true,
        ucl2ap: true,
        ucr2aucl2ap: false,
        lcrlcl: true,
        lcr2medial: true,
        lcl2medial: true,
        lcr2mediallcl2medial: false,
        lcr2a: true,
        lcl2ap: true,
        lcr2alcl2ap: false,
        uplp: true,
        upplpp: true,
        lbmb: true,
        lbpmbp: true
    },
    reportInfo: {
        patientName: '',
        patientSurname: '',
        patientDate: new Date().setFullYear(new Date().getFullYear() - 18),
        patientSex: '',
        observer: '',
        description: ''
    },
    playAnimation: true
}


const reducer = (state = initialState, action) => {
    // eslint-disable-next-line 
    switch (action.type) {
        case actionTypes.QA_SET_MEASUREMENT_LANGUAGE:
            const measurementNames = { ...strings[action.language].measurements }
            const calculatedValues = { ...state.calculatedValues }

            Object.entries(measurementNames).forEach(([key, value]) => {
                calculatedValues[key].name = value
            })


            const pointNameStrings = { ...strings[action.language].pointNames }
            Object.keys(pointNames).forEach(key => {
                pointNames[key].name = pointNameStrings[key]
            })


            return {
                ...state,
                calculatedValues
            }
        case actionTypes.QA_RESET_APP:
            const reset = { ...initialState }

            reset.customPoints = [...state.customPoints]
            points.forEach((point, index) => {
                points[index] = null
            })
            reset.customLines = [...state.customLines]
            state.customLines.forEach((customLine, index) => {
                if (!customLine.hasOwnProperty('loaded')) {
                   // console.log(lines)
                    lines.splice(lines.findIndex(line => line.point1 === customLine.point1 && line.point2 === customLine.point2), 1);
                   // console.log(lines)
                    reset.customLines.splice(index, 1)

                }
            })
            return {
                ...reset
            }
        case actionTypes.QA_LOAD_PHOTO:

            return {
                ...state,
                [action.number === 0 ? 'mainFileName' : 'suppFileName']: action.name,
                [action.number === 0 ? 'mainImage' : 'suppImage']: action.file,
                [action.number === 0 ? 'mainImageRatio' : 'suppImageRatio']: action.ratio
            }
        case actionTypes.QA_REMOVE_SUPP_PHOTO:

            const tempCustomLines = [...state.customLines]
            tempCustomLines.forEach((line, index) => {
                if (points.find(point => { if (point !== null) { return point.name === line.point1 } }).image === 1) {
                    tempCustomLines.splice(index, 1)
                }

            })
            const tempCustomPoints = [...state.customPoints]
            tempCustomPoints.forEach((customPoint, index) => {
                if (customPoint.image === 1) {
                    tempCustomPoints.splice(index, 1)
                }
            })

            points.forEach((point, index) => {
                if (point && point.image === 1) {
                    points[index] = null
                }
            })

            return {
                ...state,
                suppFileName: '...',
                suppImage: null,
                suppImageRatio: 1.3333333,
                currentImage: 0,
                customLines: [...tempCustomLines],
                customPoints: [...tempCustomPoints]
            }
        case actionTypes.QA_ROTATE_PHOTO:
            // points[0] = null
            // points[1] = null
            // points[2] = null
            // points[3] = null
            return {
                ...state,
                changingRotation: true,
                [action.number === 0 ? 'mainImageRotation' : 'suppImageRotation']: action.value,

            }
        case actionTypes.QA_START_SUBSECTION:
            return {
                ...state,
                currentPoint: pointNumbers[action.pointId],
                currentImage: action.imageNumber
            }
        case actionTypes.QA_CHANGE_CURRENT_SUBSECTION:
            return {
                ...state,
                currentSubsection: action.subsection
            }
        case actionTypes.QA_MARK_SUBSECTION_COMPLETION:
            let main = 'main'
            if (state.currentSubsection !== 'custom') {
                main = action.main === 0 ? 'main' : 'supp'
            }

            const completion = apply(state)(`subsectionComplete.${action.subsection}.${main}.${action.pointId}`, set(action.value))
            return {
                ...state,
                ...completion,
                previousPointId: action.pointId
            }

        case actionTypes.QA_NEXT_POINT:
            return {
                ...state,
                currentPoint: action.pointNumber + 1,
                currentPointId: action.pointId
            }
        case actionTypes.QA_SWITCH_IMAGE:
            if (action.imageNumber !== null) {
                return {
                    ...state,
                    currentImage: action.imageNumber,
                    playAnimation: true
                }
            } else {
                let image = state.currentImage
                if (image === 0) {
                    image = 1
                } else if (image === 1) {
                    image = 0
                }
                return {
                    ...state,
                    currentImage: image,
                    playAnimation: true
                }
            }

        case actionTypes.QA_TOGGLE_AUTOMATIC_PROGRESSION:
            return {
                ...state,
                automaticProgression: !state.automaticProgression
            }
        case actionTypes.QA_TOGGLE_ANIMATED_INSTRUCTIONS:
            return {
                ...state,
                showAnimatedInstructions: !state.showAnimatedInstructions
            }
        case actionTypes.QA_CHANGE_CAN_DRAW:
            return {
                ...state,
                canDraw: action.canDraw
            }
        case actionTypes.QA_RETOUCH_PHOTO:
            const values = {
                brightness: action.brightness,
                contrast: action.contrast,
                saturation: action.saturation
            }
            return {
                ...state,
                [action.number === 0 ? 'mainImageRetouch' : 'suppImageRetouch']: values
            }
        case actionTypes.QA_COPY_RETOUCH:
            return {
                ...state,
                suppImageRetouch: { ...state.mainImageRetouch }
            }
        case actionTypes.QA_CHANGE_REFERENCE_VALUE:
            const value = action.value.replace(',', '.')
            const changedReferenceValue = apply(state)(`referenceValues.${action.main}`, set(value))
            return {
                ...state,
                ...changedReferenceValue
            }

        case 'QA_CALCULATION_START': 
            return {
                ...state,
                awaitingValues: true
            };
        case 'QA_CALCULATION_END':
            return {
                ...state,
                awaitingValues: false,
                calculatedValues: action.calculatedValues && action.calculatedValues
            };
    
        // case actionTypes.QA_CALCULATE_VALUES:
        //     const sectionCompletion = {}
        //     let complete;
        //     Object.keys(state.subsectionComplete).forEach(key => {
        //         if (key === 'loadImages') {
        //             return
        //         }
        //         const section = { ...state.subsectionComplete[key] }
        //         if (state.suppImage) {
        //             switch (key) {
        //                 case 'upperCurve':
        //                     delete section.main
        //                     break;
        //                 case 'lowerCurve':
        //                     delete section.main
        //                     break;


        //                 default:
        //                     break;
        //             }
        //         }
        //         else {
        //             if (section.hasOwnProperty('supp')) {
        //                 delete section.supp
        //             }

        //         }


        //         Object.keys(section).forEach(secondaryKey => {

        //             complete = Object.keys(section[secondaryKey]).every((k) => { return section[secondaryKey][k] })
        //         })
        //         sectionCompletion[key] = complete
                
        //     })
        //     const prevValues = { ...state.calculatedValues }


        //     return {
        //         ...state,
        //         calculatedValues: calculateValues(prevValues, state.suppImage ? 1 : 0, state.referenceValues, sectionCompletion, state.customMeasurements)
        //     }
        case actionTypes.QA_TOGGLE_ALL_MEASUREMENTS:
            const exportAll = { ...state.exportedMeasurements }
            const areChecked = Object.keys(exportAll).every((k) => { return exportAll[k] })
            Object.entries(state.exportedMeasurements).forEach(([key, measurement]) => {
                exportAll[key] = !areChecked
            })
            return {
                ...state,
                exportedMeasurements: exportAll
            }
        case actionTypes.QA_TOGGLE_MEASUREMENT:
            const exportedMeasurements = { ...state.exportedMeasurements }
            exportedMeasurements[action.measurement] = !state.exportedMeasurements[action.measurement]
            return {
                ...state,
                exportedMeasurements
            }
        case actionTypes.QA_TOGGLE_POINT_NAMES:
            return {
                ...state,
                showPointNames: !state.showPointNames
            }
        case actionTypes.QA_TOGGLE_REFERENCE_LINES:
            return {
                ...state,
                showReferenceLines: !state.showReferenceLines,

            }
        case actionTypes.QA_TOGGLE_ALL_OBJECTS:
            return {
                ...state,
                showAllObjects: !state.showAllObjects,

            }
        case actionTypes.QA_ADD_CUSTOM_POINT:
            var complete = false
            var customPoints = [...state.customPoints]
            points.push(null)
            let lastPointId = '';
            for (lastPointId in pointNumbers) {
                pointNumbers[`customPoint${customPoints.length}`] = pointNumbers[lastPointId] + 1
            }
            if (action.name) {
                complete = apply(state)(`subsectionComplete.custom.main.customPoint${customPoints.length}`, set(false))
                if (customPoints.findIndex(customPoint => customPoint.name === action.name) < 0) {
                    let pointImage = 0
                    if (state.suppImage && action.image === 1) {
                        pointImage = 1
                    }
                    pointNames[pointNumbers[lastPointId] + 1] = { name: action.name, color: action.color }
                    customPoints.push({ name: action.name, color: action.color, image: pointImage, globalId: pointNumbers[lastPointId] + 1 })
                }
            } else {
                complete = apply(state)(`subsectionComplete.custom.main.customPoint${customPoints.length}`, set(false))
                pointNames[pointNumbers[lastPointId] + 1] = { name: "", color: "#000" }
                customPoints.push({ name: '', color: '#000', image: 0, globalId: pointNumbers[lastPointId] + 1 })
            }

            return {
                ...complete,
                customPoints,
                playAnimation: true
            }
        case actionTypes.QA_CHANGE_POINT_NAME:
            customPoints = [...state.customPoints]
            customPoints[action.id].name = action.name
            pointNames[customPoints[action.id].globalId].name = action.name
            if (points[customPoints[action.id].globalId]) {
                points[customPoints[action.id].globalId].name = action.name
            }
            return {
                ...state,
                customPoints,
                playAnimation: true
            }
        case actionTypes.QA_CHANGE_POINT_COLOR:
            customPoints = [...state.customPoints]
            customPoints[action.id].color = action.color
            pointNames[customPoints[action.id].globalId].color = action.color
            if (points[customPoints[action.id].globalId]) {
                points[customPoints[action.id].globalId].fill = action.color
            }
            return {
                ...state,
                customPoints,
                playAnimation: true
            }
        case actionTypes.QA_CHANGE_POINT_IMAGE:
            customPoints = [...state.customPoints]
            customPoints[action.id].image = action.image
            if (points[customPoints[action.id].globalId]) {
                points[customPoints[action.id].globalId].image = action.image
            }
            return {
                ...state,
                customPoints,
                playAnimation: true
            }
        case actionTypes.QA_UPDATE_CUSTOM_POINTS:
            customPoints = [...state.customPoints]

            return {
                ...state,
                customPoints,
                playAnimation: true
            }
        case actionTypes.QA_DELETE_CUSTOM_POINT:
            customPoints = [...state.customPoints]
            points.splice(customPoints[action.index].globalId, 1)
            delete pointNames[customPoints[action.index].globalId]
            customPoints.splice(action.index, 1)
            delete pointNumbers[`customPoint${action.index}`]
            complete = apply(state)(`subsectionComplete.custom.main.customPoint${action.index}`, set(false))

            return {
                ...complete,
                customPoints,
                currentPoint: 0,
                currentPointId: state.currentPointId === `customPoint${action.index}` ? '' : state.currentPointId,
                playAnimation: true
            }
        case actionTypes.QA_ADD_CUSTOM_LINE:
            var customLines = [...state.customLines]
            if (action.point1 && action.point2) {
                if (customLines.findIndex(customLine => customLine.point1 === action.point1 && customLine.point2 === action.point2) < 0) {
                    let point1 = Object.values(pointNames).filter(pointName => pointName.name === action.point1)[0]
                    let point2 = Object.values(pointNames).filter(pointName => pointName.name === action.point2)[0]
                    if(point1 && point2){
                        point1 = point1.id
                        point2=point2.id
                        if(pointNames[pointNumbers[point1]] && pointNames[pointNumbers[point2]]){
                            customLines.push({ point1: pointNames[pointNumbers[point1]].name, point2: pointNames[pointNumbers[point2]].name, loaded: true })
                            lines.push(addLine(pointNumbers[point1], pointNumbers[point2], [8, 3], 3))
                        }
                    }
                }
            } else {
                customLines.push({ point1: "", point2: "" })
                lines.push(addLine(null, null, [8, 3], 3))
            }
            return {
                ...state,
                customLines,
                playAnimation: true
            }
        case actionTypes.QA_CHANGE_LINE_POINT:
            customLines = [...state.customLines]
            action.isFirstPoint ? customLines[action.id].point1 = pointNames[action.point].name : customLines[action.id].point2 = pointNames[action.point].name
            action.isFirstPoint ? lines[7 + action.id].point1 = action.point : lines[7 + action.id].point2 = action.point
            return {
                ...state,
                customLines,
                playAnimation: true
            }
        case actionTypes.QA_DELETE_CUSTOM_LINE:
            customLines = [...state.customLines]
            customLines.splice(action.index, 1)
            lines.splice(7 + action.index, 1)
            return {
                ...state,
                customLines,
                playAnimation: true
            }
        case actionTypes.QA_ADD_CUSTOM_MEASUREMENT:
            var customMeasurements = [...state.customMeasurements]
            if (action.measurementType && action.point1) {
                customMeasurements.push({ type: action.measurementType, point1: action.point1, point2: action.point2 ? action.point2 : '', loaded: true })
            } else {
                customMeasurements.push({ type: 'distance', point1: '', point2: '' })
            }
            return {
                ...state,
                customMeasurements,

            }
        case actionTypes.QA_DELETE_CUSTOM_MEASUREMENT:
            customMeasurements = [...state.customMeasurements]
            customMeasurements.splice(action.id, 1)
            return {
                ...state,
                customMeasurements
            }
        case actionTypes.QA_CHANGE_CUSTOM_MEASUREMENT_POINT:
            customMeasurements = [...state.customMeasurements]
            action.isFirstPoint ? customMeasurements[action.id].point1 = pointNames[action.point].name : customMeasurements[action.id].point2 = pointNames[action.point].name
            return {
                ...state,
                customMeasurements
            }
        case actionTypes.QA_CHANGE_CUSTOM_MEASUREMENT_TYPE:
            customMeasurements = [...state.customMeasurements]
            customMeasurements[action.id].type = action.measurementType
            return {
                ...state,
                customMeasurements
            }
        case actionTypes.QA_CHANGE_REPORT_INFO_VALUE:
            const reportInfo = { ...state.reportInfo }
            reportInfo[action.name] = action.value
            return {
                ...state,
                reportInfo
            }
        case actionTypes.QA_TOGGLE_ANIMATION:
            return {
                ...state,
                playAnimation: action.playAnim
            }
        case actionTypes.QA_LOAD_SAVED_POINTS:

            return {
                ...state,
            }
        case actionTypes.QA_DELETE_SAVED_POINT:

            return {
                ...state,
            }
        case actionTypes.QA_SAVE_CUSTOM_POINTS:
            //TODO: change to fetch -> push -> save
            let savedPoints = localStorage.getItem('customPoints');
            if (savedPoints) {
                savedPoints = savedPoints.split(",")

                const newPointsToSave = state.customPoints.filter(point => {

                    return savedPoints.filter(savedPoint => savedPoint.name === point.name).length === 0
                })
                savedPoints = [...savedPoints, ...newPointsToSave]
                localStorage.setItem('customPoints', savedPoints.toString())
            } else {
                localStorage.setItem('customPoints', state.customPoints.toString())
            }


            return {
                ...state,
            }
        case actionTypes.QA_LOAD_SAVED_LINES:

            return {
                ...state,
            }
        case actionTypes.QA_DELETE_SAVED_LINE:

            return {
                ...state,
            }
        case actionTypes.QA_SAVE_CUSTOM_LINES:

            return {
                ...state,
            }
    }

    return state;
}


export default reducer;
