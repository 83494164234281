import { apply, set } from "redecorate";
import * as actionTypes from "../actions/actionTypes";
import {
  pointNumbers,
  points,
  pointNames,
  lines,
  addLine
} from "./../../components/Volume2D/PhotoArea/CanvasObjects";

import tutorialFrontFemale from "../../assets/images/Volume2D/front.jpg";
import tutorialRightFemale from "../../assets/images/Volume2D/right.jpg";
import tutorialLeftFemale from "../../assets/images/Volume2D/left.jpg";
import tutorialFrontMale from "../../assets/images/Volume2D/front-male.jpg";
import tutorialRightMale from "../../assets/images/Volume2D/right-male.jpg";
import tutorialLeftMale from "../../assets/images/Volume2D/left-male.jpg";

import { checkPointCoordinates } from "../../components/Volume2D/SideDrawer/Tutorial/correctPointPositions";

const initialState = {
  autostartSections: false,
  isMale: false,
  frontFileName: "...",
  rightFileName: "...",
  leftFileName: "...",
  frontImage: null,
  rightImage: null,
  leftImage: null,
  frontImageRatio: 1.3333,
  rightImageRatio: 1.3333,
  leftImageRatio: 1.3333,
  canvasWidth: 0,
  canvasHeight: 0,
  currentPoint: 0,
  previousPointId: "",
  currentPointId: "",
  currentImage: 0,
  resetImagePositionAndScale: false,
  canDraw: false,
  automaticProgression: false,
  copyReferenceLineInput: true,
  showAnimatedInstructions: true,
  currentSubsection: "referenceLine",
  subsectionComplete: {
    loadImages: {
      front: false,
      right: false,
      left: false
    },
    referenceLine: {
      front: {
        ref1a: false,
        ref1b: false
      },
      right: {
        ref2a: false,
        ref2b: false
      },
      left: {
        ref3a: false,
        ref3b: false
      }
    },
    frontCurves: {
      front: {
        right_front_medial: false,
        right_front_lateral: false,
        right_front_nipple: false,
        left_front_lateral: false,
        left_front_medial: false,
        left_front_nipple: false
      }
    },
    sideCurves: {
      right: {
        right_top: false,
        right_bottom: false,
        right_nipple: false
      },
      left: {
        left_top: false,
        left_bottom: false,
        left_nipple: false
      }
    }
  },
  frontImageRetouch: {
    brightness: 100,
    contrast: 100,
    saturation: 100
  },
  rightImageRetouch: {
    brightness: 100,
    contrast: 100,
    saturation: 100
  },
  leftImageRetouch: {
    brightness: 100,
    contrast: 100,
    saturation: 100
  },
  referenceValues: {
    front: "0",
    right: "0",
    left: "0"
  },
  customPoints: [],
  customLines: [],
  customMeasurements: [],
  showPointNames: true,
  showReferenceLines: true,
  showAllObjects: true,
  calculatedValues: {
    right: "-",
    left: "-",
    right_3d: [],
    left_3d: []
  },
  exportedMeasurements: {
    rightBreast: true,
    leftBreast: true
  },
  reportInfo: {
    patientName: "",
    patientSurname: "",
    patientDate: new Date().setFullYear(new Date().getFullYear() - 18),
    patientSex: "",
    observer: "",
    description: ""
  },
  playAnimation: true,
  showVisualizer: false,
  algorithm: "SAGITTAL",
  awaitingValues: false,
  isSavingData: false,
  tutorialMode: false,
  tutorialPoints: []
};

const reducer = (state = initialState, action) => {
  // eslint-disable-next-line
  switch (action.type) {
    case actionTypes.V2D_RESET_APP:
     

      const reset = { ...initialState };
      points.forEach((point, index) => {
        points[index] = null;
      });

      return {
        ...reset
      };
    case actionTypes.V2D_LOAD_PHOTO:
      let name, image, ratio, which;
      switch (action.number) {
        case 0:
          name = "frontFileName";
          image = "frontImage";
          ratio = "frontImageRatio";
          which = "front";
          break;
        case 1:
          name = "rightFileName";
          image = "rightImage";
          ratio = "rightImageRatio";
          which = "right";
          break;
        case 2:
          name = "leftFileName";
          image = "leftImage";
          ratio = "leftImageRatio";
          which = "left";
          break;
      }
      var completion = apply(state)(
        `subsectionComplete.loadImages.${which}`,
        set(true)
      );

      return {
        ...state,
        ...completion,
        [name]: action.name,
        [image]: action.file,
        [ratio]: action.ratio
      };

    case actionTypes.V2D_START_SUBSECTION:
      return {
        ...state,
        currentPoint: pointNumbers[action.pointId],
        currentImage: action.imageNumber
      };
    case actionTypes.V2D_CHANGE_CURRENT_SUBSECTION:
      return {
        ...state,
        currentSubsection: action.subsection
      };
    case actionTypes.V2D_MARK_SUBSECTION_COMPLETION:
      let main;
      switch (action.main) {
        case 0:
          main = "front";
          break;
        case 1:
          main = "right";
          break;
        case 2:
          main = "left";
          break;
      }
      completion = apply(state)(
        `subsectionComplete.${action.subsection}.${main}.${action.pointId}`,
        set(action.value)
      );
      return {
        ...state,
        ...completion,
        previousPointId: action.pointId
      };

    case actionTypes.V2D_NEXT_POINT:
      return {
        ...state,
        currentPoint: action.pointNumber + 1,
        currentPointId: action.pointId
      };
    case "V2D_UPDATE_TUTORIAL_POINTS":
      
      return {
        ...state,
        tutorialPoints: state.tutorialMode ? [...checkPointCoordinates(state.canvasWidth, state.canvasHeight, points, state.isMale)] : []
      };
    case actionTypes.V2D_SWITCH_IMAGE:
      if (action.imageNumber !== null) {
        return {
          ...state,
          currentImage: action.imageNumber,
          playAnimation: true
        };
      } else {
        let image = state.currentImage;
        if (image === 0) {
          image = 1;
        } else if (image === 1) {
          image = 2;
        } else {
          image = 0;
        }
        return {
          ...state,
          currentImage: image,
          playAnimation: true
        };
      }
      case actionTypes.V2D_RESET_IMAGE_POSITON_AND_SCALE:
        return {
          ...state,
          resetImagePositionAndScale: !state.resetImagePositionAndScale
        }

    case actionTypes.V2D_TOGGLE_AUTOMATIC_PROGRESSION:
      return {
        ...state,
        automaticProgression: !state.automaticProgression
      };
      case actionTypes.V2D_TOGGLE_COPY_REF_LINE_INPUT:
        return {
          ...state,
          copyReferenceLineInput: !state.copyReferenceLineInput
        };
    case actionTypes.V2D_TOGGLE_ANIMATED_INSTRUCTIONS:
      return {
        ...state,
        showAnimatedInstructions: !state.showAnimatedInstructions
      };
    case actionTypes.V2D_CHANGE_CAN_DRAW:
      let showViz = state.showVisualizer;
      if (action.canDraw) {
        showViz = false;
      }

      return {
        ...state,
        canDraw: action.canDraw,
        showVisualizer: showViz
      };
    case actionTypes.V2D_RETOUCH_PHOTO:
      const values = {
        brightness: action.brightness,
        contrast: action.contrast,
        saturation: action.saturation
      };
      let whichRetouch;
      switch (action.number) {
        case 0:
          whichRetouch = "frontImageRetouch";
          break;
        case 1:
          whichRetouch = "rightImageRetouch";
          break;
        case 2:
          whichRetouch = "leftImageRetouch";
          break;
      }
      return {
        ...state,
        [whichRetouch]: values
      };
    case actionTypes.V2D_COPY_RETOUCH:
      switch (state.currentImage) {
        case 0:
          return {
            ...state,
            rightImageRetouch: { ...state.frontImageRetouch },
            leftImageRetouch: { ...state.frontImageRetouch }
          };
        case 1:
          return {
            ...state,
            frontImageRetouch: { ...state.rightImageRetouch },
            leftImageRetouch: { ...state.rightImageRetouch }
          };
        case 2:
          return {
            ...state,
            rightImageRetouch: { ...state.leftImageRetouch },
            frontImageRetouch: { ...state.leftImageRetouch }
          };
        default:
          return{
            ...state
          }
      }
    case actionTypes.V2D_CHANGE_REFERENCE_VALUE:
      const value = action.value.replace(",", ".");
      if(state.copyReferenceLineInput){
        const newRefValues =   {
          front: value,
          right: value,
          left: value
        }
        return {
          ...state,
          referenceValues: {...newRefValues}
        }
      }else{

        const changedReferenceValue = apply(state)(
          `referenceValues.${action.main}`,
          set(value)
        );
        return {
          ...state,
          ...changedReferenceValue
        };
      }

    case "V2D_CALCULATION_START":
      return {
        ...state,
        awaitingValues: true
      }
    case "V2D_CALCULATION_END":
      return {
        ...state,
        awaitingValues: false,
        calculatedValues: action.calculatedValues ? action.calculatedValues : state.calculatedValues
      }
    
    case actionTypes.V2D_TOGGLE_ALL_MEASUREMENTS:
      const exportAll = { ...state.exportedMeasurements };
      const areChecked = Object.keys(exportAll).every(k => {
        return exportAll[k];
      });
      Object.entries(state.exportedMeasurements).forEach(
        ([key, measurement]) => {
          exportAll[key] = !areChecked;
        }
      );
      return {
        ...state,
        exportedMeasurements: exportAll
      };
    case actionTypes.V2D_TOGGLE_MEASUREMENT:
      const exportedMeasurements = { ...state.exportedMeasurements };
      exportedMeasurements[action.measurement] = !state.exportedMeasurements[
        action.measurement
      ];
      return {
        ...state,
        exportedMeasurements
      };
    case actionTypes.V2D_TOGGLE_POINT_NAMES:
      return {
        ...state,
        showPointNames: !state.showPointNames
      };
    case actionTypes.V2D_TOGGLE_REFERENCE_LINES:
      return {
        ...state,
        showReferenceLines: !state.showReferenceLines
      };
    case actionTypes.V2D_TOGGLE_ALL_OBJECTS:
      return {
        ...state,
        showAllObjects: !state.showAllObjects
      };
    case actionTypes.V2D_CHANGE_REPORT_INFO_VALUE:
      const reportInfo = { ...state.reportInfo };
      reportInfo[action.name] = action.value;
      return {
        ...state,
        reportInfo
      };
    case actionTypes.V2D_TOGGLE_ANIMATION:
      return {
        ...state,
        playAnimation: action.playAnim
      };
    case actionTypes.V2D_TOGGLE_VISUALIZATION:
      return {
        ...state,
        showVisualizer: !state.showVisualizer
      };
    case actionTypes.V2D_TOGGLE_ALGORITHM:
      let currentAlgorithm =
        state.algorithm === "SAGITTAL" ? "TRANSVERSE" : "SAGITTAL";
     
      return {
        ...state,
        algorithm: currentAlgorithm,
        awaitingValues: true
      };
    case actionTypes.V2D_LEAVE_PAGE:
     
      return {
        ...state
      };
    case actionTypes.V2D_SAVE_DATA:
     
      return {
        ...state,
        isSavingData: true
      };
    case actionTypes.V2D_SAVE_DATA_CONFIRMATION:
      return {
        ...state,
        isSavingData: false
      };

      case actionTypes.V2D_START_TUTORIAL: 
      completion = apply(state)(
        `subsectionComplete.loadImages.front`,
        set(true)
      );
      completion = apply(completion)(
        `subsectionComplete.loadImages.right`,
        set(true)
      );
      completion = apply(completion)(
        `subsectionComplete.loadImages.left`,
        set(true)
      );

      return {
          ...completion,
          frontFileName: "FRONT IMAGE",
          rightFileName: "RIGHT IMAGE",
          leftFileName: "LEFT IMAGE",
          isMale: action.isMale,
          algorithm: 'TRANSVERSE',
          frontImage: action.isMale ? tutorialFrontMale : tutorialFrontFemale,
          rightImage: action.isMale ? tutorialRightMale : tutorialRightFemale,
          leftImage: action.isMale ? tutorialLeftMale : tutorialLeftFemale,
          frontImageRatio: action.isMale ? 0.90579 : 1.360208,
          rightImageRatio: action.isMale ? 0.6385 : 1.062645,
          leftImageRatio: action.isMale ? 0.6385 : 0.9215247,
          tutorialMode: true,
          playAnimation: true,
          referenceValues: {
            front: action.isMale ? "2.8" : "2",
            right: action.isMale ? "2.8" :"2",
            left: action.isMale ? "2.8" :"2"
          },
      }
      case actionTypes.V2D_SAVE_CANVAS_SIZE: {
          return {
              ...state,
            canvasWidth: action.size.width,
            canvasHeight: action.size.height
          }
      }
  }

  return state;
};

const checkCompletion = state => {
  const sectionCompletion = {};
  let complete;
  Object.keys(state.subsectionComplete).forEach(key => {
    if (key === "loadImages") {
      return;
    }
    const section = { ...state.subsectionComplete[key] };

    Object.keys(section).forEach(secondaryKey => {
      complete = Object.keys(section[secondaryKey]).every(k => {
        return section[secondaryKey][k];
      });
    });
    sectionCompletion[key] = complete;
    // console.log(sectionCompletion)
  });
  return complete;
};

export default reducer;
