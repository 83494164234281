import React from 'react'
import styled, { keyframes } from 'styled-components'

const Spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const Container = styled.div`
height: 100vh;
width: 100vw;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
background-color: #374050;
font-family: 'OpenSans', sans-serif;
`

const Circle = styled.div`
transform: scale(2, 2);
    display: inline-block;
    width: 64px;
    height: 64px;
    &::after{
        content: " ";
        display: block;
        width: 46px;
        height: 46px;
        margin: 1px;
        border-radius: 50%;
        border: 5px solid #daa520;
        border-color: #daa520 transparent #daa520 transparent;
        animation: ${Spin} 1.2s linear infinite;
    }
`

const Error = styled.div`
color: #fff;
font-size: 2em;
text-align:center;
`

const Button = styled.div`
box-shadow: 0px 0px 4px 1px rgba(218, 165, 32, 1);
background-color: rgba(242, 242, 242, 1);
color: #3A3A3A;
font-family: inherit;
font-size: 1.2em;
font-weight: bolder;
box-sizing: border-box;
padding: 8px 2px;
margin: 2em;

cursor: pointer;
transition: all 0.25s linear;
&:hover{
    box-shadow: 0px 0px 4px 3px rgba(218, 165, 32, 1);
}
&:disabled{
    background-color: rgba(123, 123, 123, 1);
    box-shadow: none;
    cursor: not-allowed;
}
`

const PageLoading = (props) => {
  var language = window.navigator.userLanguage || window.navigator.language;
  let errorText, retryText
  if(language === 'pl-PL'){
    errorText = 'Wystąpił problem podczas ładowania strony.'
    retryText = 'Ponów próbę'
  }else{
    errorText = 'An error occured while loading the page.'
    retryText = 'Try again'
  }
  let content = null
  if (props.error) {
    content = <Error>{errorText} <Button onClick={ props.retry }>{retryText}</Button></Error>;
  } else if (props.pastDelay) {
    content = <Circle/>;
  } else {
    content = null;
  }
  return (
    <Container>
      {content}
    </Container>
  )
}

export default PageLoading
