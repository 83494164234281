import { points, pointNumbers } from './../PhotoArea/CanvasObjects';



const calculateAngle = (x2, x1, y2, y1, x4, x3, y4, y3) => {
    var dx1 = x2 - x1;
    var dy1 = y2 - y1;
    var dx2 = x4 - x3;
    var dy2 = y4 - y3;
    var d = dx1 * dx2 + dy1 * dy2;
    var l2 = (dx1 * dx1 + dy1 * dy1) * (dx2 * dx2 + dy2 * dy2)

    var angle = Math.acos(d / Math.sqrt(l2));
    angle = angle * (180 / Math.PI);
    angle = angle.toFixed(2);
    return angle;
}

export const fixRotation = () => {

    const mainRotation = calculateAngle(1, 0, 0, 0,
        points[pointNumbers['adjustRotation1a']].x,
        points[pointNumbers['adjustRotation1b']].x,
        points[pointNumbers['adjustRotation1a']].y,
        points[pointNumbers['adjustRotation1b']].y);
    const suppRotation = calculateAngle(1, 0, 0, 0,
        points[pointNumbers['adjustRotation2a']].x,
        points[pointNumbers['adjustRotation2b']].x,
        points[pointNumbers['adjustRotation2a']].y,
        points[pointNumbers['adjustRotation2b']].y);
    const result = -1 * (suppRotation - mainRotation);

    return result
}
